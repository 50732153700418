<div class="auth-container">
    <!-- <div class="auth-options row">
        <div class="auth-mode-btns col-4">
            <div *ngIf="this.isRegisterOptionActive()" class="login btn" id="login-btn-header" (click)="showLogin()">Log in</div>
            <div *ngIf="this.isLoginOptionActive()" class="register btn" id="register-btn-header" (click)="showRegister()">Registro</div>
        </div>
    </div> -->
    <div class="welcome-area">
        <a class="closing-cross" href="/"><img src="../../assets/cross2.png"></a>
        <div *ngIf="this.isRegisterOptionActive()" id="register-area">
            <h2>Regístrate aquí</h2>
            <form (ngSubmit)="onRegister()">
                <input type="email" id="register-email" placeholder="email" [(ngModel)]="registerInfo.email" name="email">
                <input type="password" id="register-pswd" placeholder="contraseña" [(ngModel)]="registerInfo.password" name="password">
                <input type="username" id="register-username" placeholder="nombre de usuario" [(ngModel)]="registerInfo.username" name="username">
                <select name="age" [(ngModel)]="registerInfo.age">
                    <option value="" disabled selected>Año de nacimiento</option>
                    <option *ngFor="let age of ages" [value]="age">{{age}}</option>
                </select>
                <select name="studyCenterType" [(ngModel)]="registerInfo.studyCenterType">
                    <option value="" disabled selected>Lugar de aprendizaje</option>
                    <option *ngFor="let place of studyCenterTypeOptions" [value]="place">{{place}}</option>
                </select>
                <div class="row" id="academy-checbox-row" *ngIf="this.academy">
                    <div class="col-9">¿Tienes un código de tu academia?</div>
                    <input class="col-3" type="checkbox" [checked]=true [attr.disabled]=true> 
                </div>
                <input *ngIf="this.academy" type="text" id="register-teacherId" placeholder="código de academia" [(ngModel)]="academyToJoinId" name="academy-code" [attr.disabled]="this.academy">
                
                <div class="row" id="teacherToJoin" *ngIf="this.academy">
                    <div class="col-4" id="academy-usernamer">{{academy.username}}</div>
                    <div class="col-8" id="academy-email">{{academy.email}}</div>
                </div>
                <input type="submit" name="Crear cuenta"  value="Crear cuenta" class="sign-up">
            </form>
            
            <p id="register-feedback-msg"></p>
        </div>
        <div *ngIf="this.isLoginOptionActive()" id="login-area">
            <h2>Iniciar sesión</h2>
            <form (ngSubmit)="onLogin()">
                <input type="email" id="login-email" placeholder="email de acceso" [(ngModel)]="loginInfo.email" name="email">
                <input type="password" id="login-pswd" placeholder="contraseña" [(ngModel)]="loginInfo.password" name="password">
                <input type="submit" name="Entrar" value="Acceder" class="enter">
            </form>
            <div class="row" id="entering-app-gif-ctr">
                <img id="entering-app-gif" class="display-none col-2" src="../../assets/loading_2.gif">
            </div>
            <!-- <a id="not-registered-link" (click)="showRegister()">¿No estás registrado todavía?</a> -->
            <a id="not-registered-link" href="/#trial-form">¿Tu academia aún no está dada de alta?</a>
            <p id="login-feedback-msg"></p>
        </div>
    </div>
</div>
