import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { StringifyOptions } from 'querystring';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  beginLink: string = "/login";
  isUserLogged: boolean = false;
  isMobileDevice: boolean;

  form: FormGroup;
  contactName: FormControl = new FormControl("", [Validators.required]);
  contactLastName: FormControl = new FormControl("", []);
  contactEmail: FormControl = new FormControl("", [Validators.required, Validators.email]);
  contactPhoneNumber: FormControl = new FormControl("", []);
            //new FormControl("", [Validators.required, Validators.maxLength(16), Validators.minLength(9)]);
  academySize: FormControl = new FormControl("", [Validators.required]);
  academyName: FormControl = new FormControl("", [Validators.required]);
  contactPersonPosition: FormControl = new FormControl("", [Validators.required]);

  submitted: boolean;

  academyStudentsNumber: string[] = ["1-10", "11-30", "31-60", "61-100", "+100"];
  academyLeadPosition: string[] = ["Profesor/a", "Equipo de administración", "Equipo de dirección", "Equipo IT", "Soy estudiante", "Otro"];

  constructor(public authService: AuthService, private _router: Router, private formBuilder: FormBuilder, private http: HttpClient, public matDialog: MatDialog) { 
    this.form = this.formBuilder.group({
      contactName: this.contactName,
      contactLastName: this.contactLastName,
      contactEmail: this.contactEmail,
      contactPhoneNumber: this.contactPhoneNumber,
      academySize: this.academySize,
      academyName: this.academyName,
      contactPersonPosition: this.contactPersonPosition 
    });
    this.submitted = false;
  }

  ngOnInit(): void {
    this.checkDeviceWidth();
    this.authService.getCurrentUser().then(
      userInfo => {
        if(userInfo !== null) this.isUserLogged = true;
      }
    );
  }

  submitFreeTrialRequest() {
    var formData: any = new FormData();
    console.log(typeof(this.contactName));
    

    formData.append("contact-name", this.contactName.value);
    formData.append("contact-lastname", this.contactLastName.value);
    formData.append("contact-email", this.contactEmail.value);
    formData.append("contact-phone", this.contactPhoneNumber.value);
    formData.append("academy-name", this.academyName.value);
    formData.append("lead-position", this.contactPersonPosition.value);
    formData.append("students-number", this.academySize.value);

    var formu = {
    "contact-name": this.contactName.value,
    "contact-lastname": this.contactLastName.value,
    "contact-email": this.contactEmail.value,
    "contact-phone"	: this.contactPhoneNumber.value,
    "academy-name": this.academyName.value,
    "lead-position": this.contactPersonPosition.value,
    "students-number": this.academySize.value
    }

    this.submitted = true;
    var json = JSON.stringify(formu);
  
    
    if(this.form.valid) {
      this.http.post("https://hook.eu1.make.com/x8xjyril66h5ax16i7wzi9xxoygovup2", json,  {responseType: 'text'}).subscribe(
      (response) => {
        this.openTrialRequestSuccessModal();
        console.log(response);
      },
      (error) => {
        this.openTrialRequestErrorModal();
        console.log(error);
      }
    )
    }
  }

  private checkDeviceWidth() {
    if (document.body.offsetWidth < 700) {
      this.isMobileDevice = true;
    }else {
      this.isMobileDevice = false;
    }
  }

  openTrialRequestSuccessModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.id = "modal-component";
    
    dialogConfig.data = {
      title: "Petición enviada",
      text: 'La petición se ha enviado correctamente, contactaremos contigo próximamente. Muchas gracias!',
      error: false,
      onAccept: () => {this.resetForm()}
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  openTrialRequestErrorModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.id = "modal-component";

    dialogConfig.data = {
      title: "Parece que ha ocurrido un error...",
      text: 'Parece que ha sucedido un error al enviar la petición, puedes escribir directamente a nuestro correo:  vokablo.academias@vokablo.com para pedir la prueba gratuita y contactaremos pronto!',
      error: true,
      onAccept: () => {this.resetForm()}
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);

  }

  private resetForm() {
    this.submitted = false;
    location.reload();
  }

}
