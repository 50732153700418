<div id="collections-container">
    <div id="collection-page-header">
        
        <header [loggedUserId]="this.userId" [email]="user" [settingsAvailable]=true [logoutAvailable]=true>
        </header>

        <div id="stats-areas" class="row">
            <div id="stats-numbers-area" class="col-md-5">
                <div *ngIf="this.collectionList" class="row">
                    <div class="col-md-12 stats-nr">{{this.collectionList?.length}}</div> <div class="col-md-12 stats-txt">colecciones guardadas</div>
                </div>
            </div>
            <div id="exp-icon-area" class="col-md-3" (click)="showAppInfo()">
                <img id="user-info-img" src="/assets/user-info.png">
            </div>
        </div>
    </div>
    <div id="menu-views" *ngIf="this.student?.teacherId">
        <div class="menu-item active" id="own-collections-view" (click)="openCollectionsView()">
            <!-- <img src="../../assets/levels-icon.png"> -->
            Mi vocabulario
        </div>
        <div class="menu-item" id="academy-view" (click)="openAcademyView()">
            Academia
        </div>
    </div>
    
    <div class="row collections-body" id="collections-list">
        <div id="collection-adder" class="col-md-4 col-sm-6 cell">
            <div class="cell adder">
                <h2 (click)="showCollectionEditor()">+</h2>
            </div>
        </div>
        <div id="collection-editor-container" class="col-md-4 col-sm-6 cell">
            <div id="collection-editor">
                <div id="editor-cross" (click)="hideCollectionEditor()">
                    <img src="/assets/cross.png">
                </div>
                <form (ngSubmit)="addNewCollection()">
                    <input type = "text" [(ngModel)]="newCollection.title" id="collection-title-input" name="title" placeholder="Tema" maxlength="18" (focus)="resetEditorStyle()" autocomplete="off">
                    <input type = "text" [(ngModel)]="newCollection.description" id="collection-desc-input" name="description" placeholder="Descripción" maxlength="28" (focus)="resetEditorStyle()" autocomplete="off">
                    <input type="submit" value="Añadir" class="btn guardar">
                </form>
            </div>
        </div>
        <div *ngIf="!this.isEditorShown && collectionList?.length === 0"  id="no-collection-found" class="col-md-9 col-12" (click)="showCollectionEditor()">Pulsa + para crear una colección</div>
        <div *ngFor="let collection of collectionList?.reverse()" class="collection-container col-md-4 col-sm-6 cell">
                <div class="collection">
                    <div class="collection-head">
                        <a class="collection-menu {{collection?.id}}" (click)="collectionMenuOnClick($event, collection)" (clickOutside)="collectionMenuOnClickOutside($event, collection)">
                            <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="coll-option-area hide" id="opt-area-{{collection?.id}}">
                            <input type="submit" class="btn {{collection?.id}}" value="Eliminar" (click)="deleteCardDeck($event)">
                        </div>
                    </div>
                    <a href="/mycards/{{collection?.id}}" class="collection-link"></a>
                    <div class="collection-info">
                        <h3>{{collection?.title}}</h3>
                        <h4>{{collection?.description}}</h4>
                    </div>
                </div>
        </div>
    </div>
    <div *ngIf="this.student?.teacherId" class="collections-body display-none" id="academy-collections-ctr">
        <academy-collections [teacherId]="this.student?.teacherId" id="academy-collections" class="row">
        </academy-collections>
    </div>
    <div id="app-info-modal" class="display-none">
        <div id="app-info-clos">
            <div id="app-info-close-btn" class="btn" (click)="closeAppInfo()"><img src="../../assets/cross2.png"></div>
        </div>
        <div id="app-info-text">
            <h2 class="custom-underline">Cómo usar la aplicación</h2>

            <div class="row" id="service-description">
                <div class="col-12">El mejor modo de aprender vocacbulario en otros idiomas es aprendiéndolo y repasándolo una y otra vez.</div>
            </div>
            <div class="row" id="collections-exp">
                <div class="col-2 icon"><img src="../../assets/collection-img.png"></div>
                <div class="col-10">Crea una colección para tu idioma o tema favorito</div>
            </div>
            <div class="row" id="cards-exp">
                <div class="col-2 icon"><img src="../../assets/card-img.png"></div>
                <div class="col-10">Comienza a crear tarjetas de vocabulario dentro de tu colección. Tómate tu tiempo para escribirlas con tranquilidad.
                    El proceso de escribir las palabras que quieres aprender con su traducción ES LA CLAVE !
                </div>
            </div>
            <div class="row" id="practice-exp">
                <div class="col-2 icon"><img src="../../assets/practice-img.png"></div>
                <div class="col-10">
                    Una vez hayas completado un listado de palabras, comienza a practicar las veces que quieras!
                    La mejor forma de aprender el vocabulario y retenerlo a largo plazo es repasarlo una y otra vez.
                </div>
            </div>
            <div class="row">
                <div class="col-12">Y por último y más importante, DIVIÉRTETE! :)</div>
            </div>
        </div>
        <div id="app-info-btn-area">
            <div class="btn custom-underline" (click)="closeAppInfo()">Aceptar</div>
        </div>
    </div>
    <div id="dark-layer" class="display-none" (click)="closeAppInfo()"></div>
</div>
