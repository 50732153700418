<div id="card-manager">
    <div id="card-page-header">
        <div id="header-actions">
            <a href="/collections" id="back-navigation-btn" class="btn">
                <img src="/assets/back-arrow.png">
            </a>
        </div> 
        <div class="row container-fluid" id="card-collection-head">
            <div [ngClass]="{'collection-info-loading': !deckTitle }" class="col-md-6 col-sm-6" id="collection-info">
                <h2>{{deckTitle}}</h2>
                <h3>{{deckDescription}}</h3>
            </div>
            <div class="col-md-2 col-sm-2" id="begin-practice-ctnr">
                <a *ngIf="cards?.length === 0" href="{{practiceUrl}}" id="begin-practice-btn" class="btn disabled">
                    <img src="/assets/train.png">
                    <!-- <img src="/assets/school-white.png"> -->
                </a>
                <a *ngIf="cards?.length !== 0" href="{{practiceUrl}}" id="begin-practice-btn" class="btn">
                    <img src="/assets/train.png">
                </a>
            </div>
        </div>
    </div>
    <div id="menu-views">
        <div class="menu-item active" id="cards-view" (click)="openCardsView()">
            <img src="../../assets/tarjeta-icon.png">
            Tarjetas
        </div>
        <div class="menu-item" id="levels-view" (click)="openLevelsView()">
            <img src="../../assets/levels-icon.png">
            Progreso
        </div>
    </div>
   <div id="card-list" class="body-area">
        <div id="options-area" class="row container-fluid">
            <div id="select-lang-view" class="col-md-2 col-sm-4 col-3">
                <div class="options-area-label">Traducción</div>
                <div class="option-btn">
                    <a id="translation-btn-container" (click)="toggleTranslation()">
                        <img src="/assets/idiomas1.png" id="clicked-translation-img" class="hidden">
                        <img src="/assets/idiomas.png" id="standard-translation-img">
                    </a>
                </div>
            </div>
            <div id="edit-cards"class="col-md-2 col-sm-4 col-3" *ngIf="this.isOwnPractice()">
                <div class="options-area-label">Seleccionar</div>
                <div class="option-btn">
                    <a id="items-sel-btn-container" ng-checked="checkall" (click)="showSelectionBoxes()">
                        <img src="/assets/items1.png" id="clicked-sel-img" class="hidden">
                        <img src="/assets/items.png" id="standard-sel-img">
                    </a>
                </div>
            </div>
            <div id="order-cards"class="col-md-2 col-sm-4 col-6">
                <div class="options-area-label">Ordenar por</div>
                <select name="cardOrdering" [(ngModel)]="cardOrder" id="card-ord-box">
                    <option value="" selected>Creación</option>
                    <option *ngFor="let o of orderingOptions" [ngValue]="o.id">{{o.name}}</option>
                </select>
            </div>
        </div>

        <app-card-creator id="card-creator-cmp" [create]="addCard" [resetCardsLanguageView]="resetCardsLanguageView" *ngIf="this.isOwnPractice()">
        </app-card-creator>

        <div id="loading-img-area" *ngIf="!cards">
            <img id="loading-img" src="/assets/loading_2.gif">
        </div>
        <div id="card-items-area" class="row container-fluid">
            <div id="empty-collection-msg" *ngIf="cards?.length === 0">0 palabras creadas</div>
            <div *ngFor="let card of cards | orderBy: cardOrder" class="tarjeta-container col-md-3 col-sm-6 col-12">
                <input type="checkbox" [checked]="checkall" class="checkbox-card hidden" id="{{card.id}}" (change)="eventCheck($event)">
                <div class="tarjeta {{card.id}}" id="tarjeta-{{card.id}}">
                    <span class="native-word" [ngClass]="{'main-word': cardOrder === 'native'}">{{card.native}}<br></span>
                    <span class="foreign-word" [ngClass]="{'main-word': cardOrder === 'foreign'}">{{card.foreign}}</span>
                    <div id="card-weights-area" class="row" *ngIf="cardOrder === 'weight'">
                        <div [ngClass]="{'filled-circle': card.weight > 0}" class="circle"></div>
                        <div [ngClass]="{'filled-circle': card.weight > 1}" class="circle"></div>
                        <div [ngClass]="{'filled-circle': card.weight > 2}" class="circle"></div>
                        <div [ngClass]="{'filled-circle': card.weight > 3}" class="circle"></div>
                    </div>
                </div>
                <label for="{{card.id}}" class="selection-layer hidden"></label>
            </div>
        </div>
        <div id="delete-btn" class="btn hidden" (click)="deleteCards()">
            <img src="/assets/trash.png">
            <div id="delete-btn-label">Borrar</div>
        </div>
    </div>
    
    <collection-progress [cards]="cards" [cardDeckId]="cardDeckId" [cardDeckOwner]="collectionOwnerId"
        id="levels-area" class="body-area row display-none">
    </collection-progress>
</div>

