import { Component, OnInit, Input } from '@angular/core';
import { CardService } from '../services/card.service';
import { CardCollection } from '../models/card-collection';

@Component({
  selector: 'academy-collections',
  templateUrl: './academy-collections.component.html',
  styleUrls: ['./academy-collections.component.scss']
})
export class AcademyCollectionsComponent implements OnInit {

  constructor(public cardService: CardService) { }

  ngOnInit(): void {
    this.cardService.setAcademyDeckCollection(this.teacherId);
    this.cardService.getAcademyDecks().subscribe(
      items => this.academyCollections = items
    );
  }

  @Input () teacherId: string;

  academyCollections: CardCollection[];

}
