<div id="settings-container">
    <div id="settings-page-header">
        
        <header [loggedUserId]="this.userId" [email]="this.loggedUserEmail" [settingsAvailable]=false [logoutAvailable]=true>
        </header>

        <div id="settings-page-title">
            <h2 id="settings-title"><img src="../../assets/settings-check.png" alt="vokablo-settings"> Configuración</h2>
        </div>
    </div>
    <div id="settings-area">
        <div id="separator"><img src="/assets/margin-icon.png"></div>
        <div class="row container-fluid settings-row">
            <div class="col-2 icon"><img src="/assets/profile-black.png"></div>
            <div class="col-10 settings-txt">{{loggedUser?.username}}</div>
        </div>
        <div class="row container-fluid settings-row">
            <div class="col-2 icon"><img src="/assets/mail-icon.png"></div>
            <div class="col-10 settings-txt">{{loggedUser?.email}}</div>
        </div>
        <div *ngIf="loggedUser?.userType != 'teacher'" class="row container-fluid settings-row">
            <div class="col-2 icon"><img src="/assets/settings-academy.png"></div>
            <div class="col-10">
                <!-- En caso de estar ya asociado a un profe -->
                <div *ngIf="joinedTeacher" class=" row teacher-row">
                    <div class="col-md-3 col-12 teach-col" id="email">{{joinedTeacher?.email}}</div>
                    <div class="col-md-3 col-12 teach-col" id="username">{{joinedTeacher?.username}}</div>
                    <div class="col-md-4 col-12 teach-col" id="codigo">{{joinedTeacher?.id}}</div>
                    <div class="col-md-2 col-12 teach-col col-btn" id="delete-btn" (click)="openDeactivationModal()"><img src="/assets/trash_blue.png"></div>
                </div>
                <!-- --- -->
                <div *ngIf="!joinedTeacher" class="col-10" id="teacher-txt">¿Tienes un código de profesor?</div>
                <form *ngIf="!joinedTeacher" (ngSubmit)="findTeacher()">
                    <input type = "text" [(ngModel)]="searchedTeacherCode" name="native" placeholder="Código de profesor" class="col-12 col-md-8">
                    <input type="submit" value="Buscar" class="btn guardar col-12 col-md-8">
                </form>
                <div *ngIf="searchedTeacher" class="col-12 foundTeacher">
                    <h3>Profesor encontrado</h3>
                    <div class="row teacher-row">
                        <div class="col-md-4 col-12" id="email">{{searchedTeacher?.email}}</div>
                        <div class="col-md-4 col-12" id="username">{{searchedTeacher?.username}}</div>
                        <div class="col-md-4 col-12" id="join-cntr">
                            <input type="button" value="Apuntarme" (click)="updateTeacherCode()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>