<div id="page-header">
    <header [loggedUserId]="" [settingsAvailable]=false [logoutAvailable]=false>
    </header>
    <div class="row maxw-1300" id="title-row">
        <a href="/students" id="back-arrow">
            <img src="../../assets/back-arrow-wh.png"> <span>alumnos</span>
        </a>
        <div class="col-12" id="title">
            <h1>PRÁCTICAS</h1>
        </div>
    </div>
    <div class="row maxw-1300" id="st-name">
        <h2>{{studentName}}</h2>
    </div>
    <div *ngIf="this.practices" class="row" id="main-metrics">
        <div id="practice-total" class="col-3 head-metric">
            <div class="metric-nr">{{this.practices.length}}</div>
            <div class="metric-label">prácticas completadas</div>
        </div>
        <div id="collections-total" class="col-3 head-metric">
            <div class="metric-nr">{{getNumberOfDistinctCollectionsPracticed()}}</div>
            <div class="metric-label">unidades estudiadas</div>
        </div>
        <div id="average-result" class="col-3 head-metric">
            <div class="metric-nr">{{getAnswerSuccesRate()}}%</div>
            <div class="metric-label">acierto promedio</div>
        </div>
    </div>
</div>
<div class="page-body">
    <div class="body-title row">
        <h3>RECIENTES</h3>
    </div>
    <div id="practices-list" class="row" *ngFor="let practicesOfDay of this.groupedPracticesByDay">
        <div class="col-12 practice-day">
            <h4>{{practicesOfDay.day | date:'fullDate'}}</h4>
        </div>
        <div class="col-12 row practices-of-day">
            <div class="practice row col-12 col-md-5" *ngFor="let practice of practicesOfDay.practices">
                <div class="collection-info col-8">
                    <div class="collection-name">{{practice.name}}</div>
                    <div class="collection-description">{{practice.description}}</div>
                </div>
                <div class="practice-result col-4 row">
                    <div class="practice-grade">{{getPracticeResultInPercentage(practice.id)}}</div>
                    <div class="percentage">%</div>
                    <div class="practice-nr-answers col-12">{{getNumberOfAnswersText(practice.totalAnswers)}}</div>
                </div>
                <div class="result-bar col-12 row">
                    <div class="progress-bar" id="bar-{{practice.collectionId}}" [ngStyle]="{'width' : this.getProgress(practice.correctAnswers, practice.totalAnswers) + '%' }">
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>