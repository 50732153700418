import { Component, OnInit, Input } from '@angular/core';
import { CardWeightManager } from '../services/card-weight-manager';
import { vocablo } from '../models/vocablo';
import { Router } from '@angular/router';

@Component({
  selector: 'collection-progress',
  templateUrl: './collection-progress.component.html',
  styleUrls: ['./collection-progress.component.scss']
})
export class CollectionProgressComponent implements OnInit {

  constructor(public cardWeightManager: CardWeightManager, private _router: Router) { }

  ngOnInit(): void {
  }

  @Input () cards: vocablo[];
  @Input () cardDeckId: string;
  @Input () cardDeckOwner: string;

  levelsConfig = [
    {id: '4', colorclass: 'level-color-purple', name:'OBJETIVO', label: '4 aciertos', weight: 4},
    {id: '3', colorclass: 'level-color-yellow', name:'CONTROL', label: '3 aciertos', weight: 3},
    {id: '2', colorclass: 'level-color-red', name:'DESPEGUE', label: '2 aciertos', weight: 2},
    {id: '1', colorclass: 'level-color-blue', name:'INICIACIÓN', label: '0/1 aciertos', weight: 1}
  ]

  collectionProgres: string = '0';

  setCollectionProgress() {
    /*  PENSAR MEJOR ESTA FUNCION PORQUE DEBE EMEPEZAR DESDE EL 0 / suelo, (a pesar de que el nivel minimo sea 1)*/
    const maxRocketHeight = 95;
    const minRocketHeight = 5;
    let averageWeight = this.cardWeightManager.getCollectionAverageScoreByWeight(this.cards); //media de avance (lo minimo es estar en la fase 1 y lo maximo en la  4) Es como si solo huubiera 3 fases en lugar de 4
    let progressCoef = (averageWeight-1)/3;
    if(!Number.isNaN(progressCoef)) {
      this.collectionProgres = (progressCoef*100).toFixed(0);
    }
    let rocketHeightProgress = progressCoef * (maxRocketHeight - minRocketHeight) + minRocketHeight;

    let rocket = document.getElementById('rocket');
    rocket.style.bottom = rocketHeightProgress+'%';
  }

  getNumberOfCardsInLevel(id: number) {
    let counter = 0;
    if(this.cards !== null && this.cards !== undefined){

      if (id == 4) return this.getNumberOfCardsInTheLastLevel(this.cards);
      if (id == 1) return this.getNumberOfCardsWithWeights([0,1], this.cards);
      if (id == 2) return this.getNumberOfCardsWithWeights([2], this.cards);
      if (id == 3) return this.getNumberOfCardsWithWeights([3], this.cards);
    }
    return counter;
  }

  getPracticeRoute(levelId): string {
    let ownPracticeRoute = "/practice/"+this.cardDeckId+"/l"+levelId;
    
    if(this.cardDeckOwner !== null) return ownPracticeRoute+"/"+this.cardDeckOwner;
    return ownPracticeRoute;
  }

  private getNumberOfCardsWithWeights(validWeights: number[], cards: vocablo[]): number {
    let counter = 0;
    cards.forEach(card => {
      if(validWeights.includes(card.weight)) counter++;
      if(validWeights.includes(0) && card.weight === undefined) counter ++; //En caso de que la propiedad weight no esté definida la contamos como 1er nivel
    })

    return counter;
  }

  private getNumberOfCardsInTheLastLevel(cards: vocablo []): number{
    let counter = 0;
    cards.forEach(card => {
      if(card.weight >= 4) counter++;
    })

    return counter;
  }
}
