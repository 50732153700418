// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAeeWXPl-fZRZVLK3g2KQpbiD_3ecTKDUg",
    authDomain: "vokablo-12f1d.firebaseapp.com",
    projectId: "vokablo-12f1d",
    storageBucket: "vokablo-12f1d.appspot.com",
    messagingSenderId: "1045855770551",
    appId: "1:1045855770551:web:e92824d5ca590b0123ba32",
    measurementId: "G-339VKYBH07"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
