import { vocabloPractice } from '../models/vocabloPractice';
import { vocablo } from '../models/vocablo';
import { CardService } from '../services/card.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentData } from 'angularfire2/firestore';
import { CardState } from '../models/card-state';
import {map} from 'rxjs/operators'

@Injectable(
    )
export class CardWeightManager {

    firebaseCardsStatesCollection: AngularFirestoreCollection<CardState>;
    cardStates: CardState[];
    cardStateDoc: AngularFirestoreDocument<CardState>;

    constructor(public cardService: CardService, public firebase: AngularFirestore) {}

    public updateOwnCardsWithPracticeResults(results: vocabloPractice[], practiceCards: vocablo[], userId: string, collectionId: string){
        for(var result of results) {
            var card = this.mapAnswer(result.originCardId, practiceCards);
        
            if(this.exists(card)) {
                if (result.success === 'true') {
                    this.updateCardWeight(result.success, card);
                    this.cardService.updateCardWeight(card, userId, collectionId);
                }
            }
        }
    }

    public getUserCardStates(): CardState[] {
        return this.cardStates;
    }

    public async getCollectionCardStatesSnapshot(ownerId: string, userId: string, collectionId: string){
        var cardsRef = this.firebase.collection(`academyCardsProgress/${ownerId}/students/${userId}/collections/${collectionId}/cards`).ref;

        try{
          var list: DocumentData [];
          list = await (await cardsRef.get()).docs
          return list.map(state => this.mapCardStateVO(state));
        }catch(error){
          console.log(error);
        }
      }
       
      private mapCardStateVO(cardStateVO: DocumentData): CardState {
        let cardState: CardState;
        cardState = {
          progress: cardStateVO.data().progress,
          id: cardStateVO.id
        }
        return cardState;
      }

    public setUserCardStates(collectionId: string, ownerId: string, userId: string) {
        this.firebaseCardsStatesCollection = this.firebase.collection(`academyCardsProgress/${ownerId}/students/${userId}/collections/${collectionId}/cards`);
        this.firebaseCardsStatesCollection.snapshotChanges()
        .pipe(
            map(changes => {
                return changes.map(a=>{
                    const data = a.payload.doc.data() as CardState;
                    data.id = a.payload.doc.id;
                    return data;
                });
        }))
        .subscribe(states => 
            this.cardStates = states
        );
    }

    public updateNotOwnedCardsPracticeResults(results: vocabloPractice[], practiceCards: vocablo[], userId: string, ownerId: string, collectionId: string) {
        this.setUserCardStates(collectionId, ownerId, userId);
        results.forEach(result => {
            if(result.success === "true") {
                let score = this.increaseCardProgressScore(result, practiceCards);
                this.updateCardStudentProgress(score, result.originCardId, collectionId, ownerId, userId);
            }
            }
        );
    }

    private increaseCardProgressScore(cardResult: vocabloPractice, cards: vocablo[]): number {
        let card = cards.find(card => card.id == cardResult.originCardId);
        return this.getNonNullWeight(card) + 1;
    }

    private getNonNullWeight(card: vocablo): number {
        if(card.weight == null) return 0;
        return card.weight;
    }

     // Es posible que el dato collectionId puede que sobre. O tal vez podamos meterlo como atributo en la entidad cardState
    private updateCardStudentProgress(updatedCardProgress: number, cardId: string, collectionId: string, cardOwnerId: string, userId: string) {
        this.firebaseCardsStatesCollection.doc(cardId).set({
            progress: updatedCardProgress
          }).catch(error => {
              console.log(error);
          })
    }

    public resetCardCollectionWeights(cards: vocablo[], userId: string, collectionId: string) {
        for(var card of cards) {
            card.weight = 0;
            this.cardService.updateCardWeight(card, userId, collectionId);
        }
    }

    /* ESTO IGUAL HABRÍA QUE PONERLO EN OTRO SERVICIO PORQUE ES LOGICA DE NEGOCIO PURA Y NO CONSULTA NADA EN BD */
    public getCollectionAverageScoreByWeight(cards: vocablo[]) : number{
        let sumWeights = 0;
        
        for (var card of cards) {
            sumWeights = sumWeights + this.mapCardLevels(card.weight);
        }
        let averageWeight = sumWeights / cards.length;
        return averageWeight;
    }

    private mapCardLevels(weight: number) :number{
        if (weight == null || weight <= 1) return 1;
        if(weight === 2) return 2;
        if(weight === 3) return 3;
        return 4;
    }

    private updateCardWeight(isRight: string, card: vocablo) {
        if(card.weight == null){
            card.weight = 0;
        }
        card.weight = card.weight+1;
    }

    private mapAnswer(id: string, cardList: vocablo[]): vocablo{
        for(var card of cardList){
            if(card.id === id) return card;
        }
        return null;
    }

    private exists(card: vocablo){
        return card !== null && card !== undefined
    }
}