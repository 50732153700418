<div *ngFor="let collection of academyCollections" class="collection-container col-md-4 col-sm-6 cell">
    <div class="collection">
        <div class="collection-head">
            <a class="collection-menu {{collection?.id}}">
                <i class="fa fa-ellipsis-h"></i>
            </a>
            <div class="coll-option-area hide" id="opt-area-{{collection?.id}}">
                <!-- <input type="submit" class="btn {{collection?.id}}" value="Eliminar" (click)="deleteCardDeck($event)"> -->
            </div>
        </div>
        <a href="/mycards/{{collection?.id}}/{{this.teacherId}}" class="collection-link"></a>
        <div class="collection-info">
            <h3>{{collection?.title}}</h3>
            <h4>{{collection?.description}}</h4>
        </div>
    </div>
</div>