import { Component, OnInit } from '@angular/core';
import { ListedStudentInfo } from '../models/listed-student-info';
import { AuthService } from '../services/auth.service';
import { UserDataService } from '../services/user-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {

  academyStudents: ListedStudentInfo[];
  loggedUserId: string;
  studentListLimit: number = 10;
  studentListSize: number = 0;
  studentListEmpty: boolean;

  constructor(public userDataService: UserDataService, public authService: AuthService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().then(
      userInfo =>{
        this.loggedUserId = userInfo.uid;
        if(!this.isUserTypeTeacher()) return; //TODO

        this.retrieveStudentList();
      }
    )
  }

  private retrieveStudentList() {
    this.userDataService.findActiveStudentListByTeacherId(this.loggedUserId).then(
      data => {
        this.academyStudents = data;
        //this.academyStudents.push(this.buildFakeStudent());
        this.studentListSize = this.academyStudents.length;
        this.studentListEmpty = this.studentListSize == 0;
        console.log(this.studentListEmpty);
      }
    ).catch(error => {
      this.studentListEmpty = true;
    });
    
  }

  openDeactivationModal(studentId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.id = "modal-component";
    
    dialogConfig.data = {
      title: "Eliminar alumno",
      text: "El alumno dejará de tener acceso a las colecciones y tarjetas de esta academia.",
      onAccept: () => this.deactivateSubscription(studentId)
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  private deactivateSubscription(studentId: string) {
    this.userDataService.deactivateStudentTeacherSubscription(studentId, this.loggedUserId, this.reload).then(
      () => {
        console.log("Desactivación de estudiante realizada");
      }
    );
    
  }

  private reload = () => {
    window.location.reload();
  }

  private buildFakeStudent(): ListedStudentInfo {
    let fake: ListedStudentInfo = {
      email: "hola@gmail.com",
      username: "Pedro"
    }
    return fake;
  }

  private isUserTypeTeacher() {
    //TODO
    return true;
  }

}
