import { Component, OnInit, HostListener } from '@angular/core';
import { CardService } from '../services/card.service';
import { vocablo } from '../models/vocablo';
import { vocabloPractice } from '../models/vocabloPractice';
import { AuthService } from '../services/auth.service';
import { PracticeDataService } from '../services/practice-data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { analyzeFileForInjectables } from '@angular/compiler';
import { CardWeightManager } from '../services/card-weight-manager';
import { CardState } from '../models/card-state';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.css']
})
export class PracticeComponent implements OnInit {

  practiceList: vocablo[];
  currentCard: vocablo;
  practiceCards:vocabloPractice[] = [];
  cardStates: CardState[];
  currentAnswer: string;
  progress: number;
  total: number;
  collectionId: string = "";
  userId="";
  collectionOwnerId="";

  constructor(public cardService: CardService, 
    public authService: AuthService,
    public cardWeightManager: CardWeightManager, 
    private practiceDataService: PracticeDataService,
    private _route: ActivatedRoute,
    private _router: Router) {}

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      console.log(params);
      if(!this.isPracticeGroupParamValid(params.group)) {
        alert("La práctica no ha sido encontrada");
        this.navigateToCollectionsListPage();
      }
      this.collectionId = params.collection;
      this.setCollectionOwner(params.owner);
      this.setPracticeCards(params.collection, params.group);
    })
  }

  practiceGroupAvailableParams: string [] = ['all', 'l1', 'l2','l3','l4'];

  private isPracticeGroupParamValid(param: string) {
    return this.practiceGroupAvailableParams.includes(param).valueOf();
  }

  private setCollectionOwner(ownerId: string) {
    if (ownerId === undefined || ownerId == null) return;
    this.collectionOwnerId = ownerId;
  }

  private async setCollectionCardWeightsIfNecessary() {
    if(!this.isOwnPractice()) {
      this.cardStates = await this.cardWeightManager.getCollectionCardStatesSnapshot(this.collectionOwnerId, this.userId, this.collectionId);
    }
  }

  private setPracticeCards(collectionId: string, group: string) {
    this.authService.getCurrentUser().then(
      userInfo => {
        this.userId = userInfo.uid;
        if(this.isOwnPractice()) {
          this.setUpCollectionPractice(userInfo, userInfo.uid, collectionId, group);
        } else {
          console.log("setUpCollectionPractice - de Academia");
          this.setCollectionCardWeightsIfNecessary();
          this.setUpCollectionPractice(userInfo, this.collectionOwnerId, collectionId, group);
        }
      }
      )
      .catch(error => {
        this.redirectToLogin();
      })
  }

  // CARNE DE REFACTOR
  private setUpCollectionPractice(userInfo:any, practiceOwnerId: string, collectionId: string, group: string) {
    this.cardService.existsCardDeck(practiceOwnerId, collectionId).then(
      exists => {
        if(! exists){
          console.log(practiceOwnerId+" - collection:"+collectionId);
          alert("La práctica no ha sido encontrada");
          this.navigateToCollectionsListPage();
          return;
        }
        this.progress = 0;
        this.getCardDeckSnapshot(practiceOwnerId, collectionId, group);
      }
    )
    .catch(error => {
      // AQUI CREO NO LLEGA PORQUE PASA POR EL !EXISTS
      //console.log(error);
    })
  }

  private async getCardDeckSnapshot(userId, collectionId, cardGroup){
    try{
      console.log(this.cardStates);
      var cards = this.shuffle(
        this.filterCardsByGroups(
          cardGroup, 
          await (await this.cardService.getCardListSnapshot(userId, collectionId))
                  .map(card => this.mapCardStateIfIsNecessary(card))
        )
      );
      if(cards.length == 0) {
        console.log("no hay tarjetas suficientes de ese nivel");
        this.navigateToCollectionCardsPage();
      }else {
        this.practiceList = cards;
        this.total = cards.length;
        this.currentCard = cards[this.progress];
        this.updateProgressBar();
      }
    }catch(error){
      this.navigateToCollectionCardsPage();
    }
  }

  private mapCardStateIfIsNecessary(card: vocablo) {
    if(this.isOwnPractice()) return card;

    let state = this.cardStates.find(state => state.id === card.id);
    if(!state) return card;

    card.weight = state.progress;
    return card;
  }

  private filterCardsByGroups(group: string, cards: vocablo[]) {
    if(group === "all") return cards;
    if(group === "l1") return this.getCardsByWeight(cards, [0,1]);
    if(group === "l2") return this.getCardsByWeight(cards, [2]);
    if(group === "l3") return this.getCardsByWeight(cards, [3]);
    if(group === "l4") return this.getCardsByWeight(cards, [4]);
  }

  private getCardsByWeight(cards: vocablo[], weights: number[]): vocablo[] {
    let filteredCards: vocablo[] = [];
    for(let card of cards) {
      if(weights.includes(card.weight)){
        filteredCards.push(card);
      }
      if (weights.includes(0) && card.weight=== undefined) {
        filteredCards.push(card);
      }
      // si tiene más que 4 aciertos lo añadimos
      if(weights.includes(4) && card.weight > 4) {
        filteredCards.push(card);
      }
    }
    return filteredCards;
  }

  private navigateToCollectionCardsPage(){
    alert("La práctica no ha sido encontrada");
    this._router.navigate([this.buildCollectionPageUrl()]);
  }

  private navigateToCollectionsListPage() {
    this._router.navigate(['/collections']);
  }

  buildCollectionPageUrl(): string{
    if(this.isOwnPractice()) {
      return "/mycards/"+this.collectionId;
    } else {
      return "/mycards/"+this.collectionId+"/"+this.collectionOwnerId;
    }
  }

  private redirectToLogin(){
    alert("Debes realizar el login con tu usuario para poder acceder a prácticas");
    this._router.navigate(['/login']);
  }

  onAnswer() {
    this.progress ++;
    this.updateProgressBar();

    this.currentAnswer = (<HTMLInputElement>document.getElementById("answer-area")).value;
    this.practiceCards.push(this.newPracticeVocablo(this.isAnswerRight()));

    this.getFeedback();
    this.disableForm();
  }

  private disableForm(){
    let inputArea = (<HTMLInputElement>document.getElementById("answer-area"));
    let answerBtn = (<HTMLInputElement>document.getElementById("answerBtn"));
    let passBtn = (<HTMLInputElement>document.getElementById("passBtn"));
    inputArea.setAttribute("disabled", "true");
    answerBtn.setAttribute("disabled", "true");
  }

  private enableForm(){
    let inputArea = (<HTMLInputElement>document.getElementById("answer-area"));
    let answerBtn = (<HTMLInputElement>document.getElementById("answerBtn"));
    let passBtn = (<HTMLInputElement>document.getElementById("passBtn"));
    inputArea.removeAttribute("disabled");
    answerBtn.removeAttribute("disabled");
  }

  isAnswerRight(){
    if(this.currentAnswer.toUpperCase() === this.currentCard.foreign.toUpperCase()) return 'true';
    return 'false';
  }

  newPracticeVocablo(result: string): vocabloPractice{
    let value: vocabloPractice = {
      originCardId: this.currentCard.id,
      foreign: this.currentCard.foreign,
      native: this.currentCard.native,
      answer: this.currentAnswer,
      success: result
    }
    return value;
  }

  getNextCard() {
    if(this.isLastCard()){
      this.showPracticeSummary();
      return;
    } 
    this.setFeedbackStyle("display-none");
    this.enableForm();
    this.clearAnswerArea();
    if(! this.isLastCard()) {
      this.currentCard = this.practiceList[this.progress];
      this.focusAnswerField();
    }
  }
  updateProgressBar(){
    let progressRate = (this.progress*100/this.total).toFixed(2)+"%";
    (<HTMLDivElement>document.getElementById("practice-progress")).style.width = progressRate;
  }
  
  isLastCard(){
    if(this.progress >= (this.practiceList.length)){
      //console.log("Ultima carta -- guardamos...");
      return true;
    } 
    return false;
  }
  getFeedback(){
    let message = document.getElementById("result-message");
    message.innerText = this.getFeedbackResultMessage();
    this.setFeedbackStyle(this.mapAnswerResultToClass());
    this.focusFeedbackNextBtn();
  }
  private focusFeedbackNextBtn(){
    var field = (<HTMLInputElement> document.getElementById("feedback-continue-btn"));
    field.focus();
  }
  private getFeedbackResultMessage(){
    if(this.isAnswerRight() == 'false') return "La respuesta es incorrecta :( Ánimo !!";
    return "Genial !!!";
  }
  private setFeedbackStyle(feedbackClass: string){
    let area = (<HTMLDivElement>document.getElementById("feedback-coloured-area"));
    let button = document.getElementById("feedback-continue-btn");
    
    button.removeAttribute("class");
    button.classList.add("btn", feedbackClass);

    area.removeAttribute("class");
    area.classList.add(feedbackClass)
  }

  private clearAnswerArea(){
    (<HTMLInputElement>document.getElementById("answer-area")).value = '';
  }
  private focusAnswerField(){
    var field = (<HTMLInputElement> document.getElementById("answer-area"));
    field.focus();
  }
  private mapAnswerResultToClass(){
    if(this.isAnswerRight() == 'false') return "error";
    return "success"; 
  }
  private showPracticeSummary(){
    let feedbackArea = (<HTMLDivElement>document.getElementById("feedback-coloured-area"));
    let formArea = (<HTMLDivElement>document.getElementById("practice-area"));
    feedbackArea.classList.add("display-none");
    formArea.classList.add("display-none");

    let summaryArea = (<HTMLDivElement>document.getElementById("practice-summary-area"));
    summaryArea.removeAttribute("class");
    this.showPracticeFinalScore();
    this.savePracticeResults();
  }

  private savePracticeResults() {
    if(this.isOwnPractice()) {
      this.cardWeightManager.updateOwnCardsWithPracticeResults(this.practiceCards, this.practiceList, this.userId, this.collectionId);
    } else {
      this.cardWeightManager.updateNotOwnedCardsPracticeResults(this.practiceCards, this.practiceList, this.userId, this.collectionOwnerId, this.collectionId);
      this.practiceDataService.addNewUserPracticeResult(this.collectionOwnerId, this.userId, this.collectionId, this.practiceCards);
    }
  }

  private isOwnPractice(): boolean {
    return this.collectionOwnerId === "" || this.collectionOwnerId == null;
  }

  private showPracticeFinalScore(){
    let scoreText = <HTMLHeadElement>document.getElementById("final-score");
    scoreText.innerText = this.getFinalScore()+" puntos";
  }

  private getFinalScore(){
    var score = 0;
    var total = 0;
    this.practiceCards.forEach(answer =>{
      if(answer.success === 'true') {
        score++; total++;
      }
    })
    return score+"/"+this.total;
  }

  public reloadPractice(){
    window.location.reload();
  }

private shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

}
