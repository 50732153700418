<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
    <img *ngIf="!data.error" src="../../assets/check-list.png"> 
    {{data.text}}
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="data.onAccept()">Aceptar</button>
    <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
