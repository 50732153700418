import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, DocumentData } from 'angularfire2/firestore' 
import { ListedStudentInfo } from '../models/listed-student-info';
import { UserInfo } from '../models/user-info';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  firebaseUsers: AngularFirestoreCollection<UserInfo>;
  firebaseSubscribedStudentsCollection: AngularFirestoreCollection<ListedStudentInfo>;
  
  constructor(public firebase: AngularFirestore) { }

  async saveNewUser(userId, email, username, age, studyCenterType){
    var newUser: UserInfo = {
      id: userId,
      email: email,
      username: username,
      age: age,
      studyCenterType: studyCenterType,
      creationDate: this.getCurrentDate().toDateString(),
      userType: 'standard'
    }
    var usersRef = this.firebase.collection("users");
    usersRef.doc(userId).set(newUser).then(() => Promise.resolve());
  }

  async searchTeacherToJoin(teacherId: string, userId: string) {
    var teacherToSubscribe = this.firebase.doc(`users/${teacherId}`).ref;
    this.firebaseSubscribedStudentsCollection = this.firebase.collection(`users/${teacherId}/studentList`);
    
  }

  async findUserInfoById(userId) {
    var userRef = this.firebase.doc(`users/${userId}`).ref;
    let user = this.mapUserInfo((await userRef.get()).data());

    if(user !== null) return user;
    return Promise.reject();
  }

  async findTeacherById(teacherId: string) {
    var teacherUser = this.firebase.doc(`users/${teacherId}`).ref;

    let profesor = this.mapUserInfo((await teacherUser.get()).data());
    
    if(profesor.userType === 'teacher') return profesor;
    return Promise.reject();
  }

  async findActiveStudentListByTeacherId(teacherId: string) {
    var studentListRef = this.firebase.collection(`users/${teacherId}/studentList`).ref;

    try{
      let studentList = await (await studentListRef.get()).docs;
      return studentList
                .map(studentInfo => this.mapListedStudentInfo(studentInfo))
                .filter(student => student.isSubscriptionActive);
    } catch(error){
      console.log(error);
    }
  }

  private mapListedStudentInfo(studentInfo: DocumentData) {
    let student: ListedStudentInfo;
    student = {
      email: studentInfo.data().email,
      id: studentInfo.data().id,
      isSubscriptionActive: studentInfo.data().isSubscriptionActive,
      username: studentInfo.data().username,
      subscriptionDate: studentInfo.data().subscriptionDate
    }
    return student;
  }

  async deactivateStudentTeacherSubscription(studentId: string, teacherId, onDeactivation): Promise<any> {

    // NO HE CONSEGUIDO AUN QUE FUNCIONE BIEN LA RESPUESTA Y QUE RESFRESQUE LA PAGINA UNA VEZ ACTUALIZADOS LOS DATOS.

    var subscribedTeacherStudentDoc = this.firebase.doc(`users/${teacherId}/studentList/${studentId}`).ref;
    var studentDoc = this.firebase.doc(`users/${studentId}`).ref;
    console.log(studentDoc);

    subscribedTeacherStudentDoc.update({isSubscriptionActive: false})
    .then(() => {
      studentDoc.update({teacherId: null})
      .then(() => {
        console.log("resulto en servicio");
        onDeactivation();
        Promise.resolve();
      })
      .catch(error => {
        Promise.reject(error);
      });
    })
    .catch(error => {
      Promise.reject(error);
    });
  }

  async updateUserAcademyInfo(userId: string, teacherId: string, onSubscription) {
    //buscar usuario y que sea profesor (traer info)
    //si lo es, traer lista de alumnos que tiene
    // agregar en la lista si hay espacio libre al alumno y si no está ya suscrito
    var teacherToSubscribe = this.firebase.doc(`users/${teacherId}`).ref;
    this.firebaseSubscribedStudentsCollection = this.firebase.collection(`users/${teacherId}/studentList`);
    
    var studentToUpdateRef = this.firebase.doc(`users/${userId}`);
    let newStudent: ListedStudentInfo = await this.buildActiveStudentInfoObject(userId);

    teacherToSubscribe.get().then(
      doc => {
        console.log(doc.exists);
        let usuario = this.mapUserInfo(doc.data());

        if(usuario.userType === 'teacher') {
          /*Falta Chequear que no se pasa del límite de alumnos contratado */
          console.log("El usuario buscado SÍ es un profe");
          // sJ2lnjiZxuOqCiqBvx41O1uvJEm1 
          console.log(newStudent);
          this.firebaseSubscribedStudentsCollection.doc(newStudent.id).set(newStudent).then(
            /*--> de esta forma el problema que hay, es que si ya estaba suscrito, se reescribe el subscriptionDate*/
            () => {
                studentToUpdateRef.update({
                  teacherId: teacherId
                }).then(() => {
                  onSubscription();
                  return 'association_success';
                }
                ).catch(error => {
                  console.log(error);
                  return 'association_fail';
                })
            }
          )
          .catch(error =>{
            return 'association_fail';
          })
        }
        else {
          console.log("El usuario buscado como profesor no es válido");
        }
      }
    )
    .catch(error => {
      console.log(error);
      console.log("Parece que el id del profesor introducido no sea valido...")
    })
  }

  private mapUserInfo(userInfo: DocumentData): UserInfo{
    console.log(userInfo);
    let user: UserInfo = userInfo;
    return user;
  }

  private async buildActiveStudentInfoObject(userId: string):Promise<ListedStudentInfo> {
    var studentRef = this.firebase.doc(`users/${userId}`).ref;
    let user: UserInfo = (await studentRef.get()).data();
  
    let studentInfo: ListedStudentInfo = {
      id: user.id,
      email: user.email,
      username: user.username,
      subscriptionDate: firebase.firestore.FieldValue.serverTimestamp(),
      isSubscriptionActive: true
    }
    return studentInfo;
  }

  private getCurrentDate(){
    return new Date;
  }
}
