import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: true
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], orderingArg: string): any[] {
    if(!value) return;
    if(orderingArg === '') return value.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
    //(orderingArg === 'creationDate') return value.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
    if(orderingArg === 'weight') return value.sort((a, b) => this.handleWeightOrder(a,b));
    if (orderingArg)
      return value.sort((a: any, b: any) => b[orderingArg].localeCompare(a[orderingArg])*-1);
    else
      return value;
  }

  private handleWeightOrder(a, b) {
    if(a.weight == null) return -1;
    if(b.weight == null) return 1;
    if(a.weight > b.weight) return 1;
    return -1;
  }

}