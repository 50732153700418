<div id="top-bar" class="row">
    <div class="col-3" id="bar-leftside">
        <a href="/collections">
            <h1>
                <img src="../../assets/vocablo-single-white/vocablo-single-bluexs.png" alt="vokablo-simple-logo">
            </h1>
        </a>
    </div>
    <div class="col-9" id="bar-rightside">
        <div id="user-email" *ngIf="this.email">
            {{email}}
        </div>
        <div id="profile" *ngIf="this.settingsAvailable">
            <a href="/settings"><img src="/assets/profile.png"></a>
        </div>
        <div id="logout" *ngIf="this.logoutAvailable && this.email">
            <div (click)="logout()" class="btn"><img src="/assets/logout.png"></div>
        </div>
    </div>
</div>