<div id="card-creator" class="collapse-editor row container-fluid">
    <div id="form-area" class="col-10">
        <form (ngSubmit)="onSubmit()">
            <input type = "text" [(ngModel)]="currentCard.native" name="native" placeholder="En tu idioma">
            <input type = "text" [(ngModel)]="currentCard.foreign" name="foreign" placeholder="Traducción">
            <input type="submit" value="Añadir" class="btn guardar">
        </form>
    </div>
    <div id="collapse-btn" class="col-2" (click)="toggleEditor()">
        <img src="/assets/arrow-back.png">
    </div>
    <div id="creator-tab" (click)="toggleEditor()">
        <img src="/assets/add.png">
    </div>
</div>