import { Component, OnInit } from '@angular/core';
import { Practice } from '../models/practice';
import { UserInfo } from '../models/user-info';
import { PracticeDataService } from '../services/practice-data.service';
import { AuthService } from '../services/auth.service';
import { UserDataService } from '../services/user-data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  practices: Practice[];
  groupedPracticesByDay: GroupedPracticeList[];


  studentId="";
  collectionOwnerId="";

  studentName = "";

  constructor(private practiceDataService: PracticeDataService, 
    private userDataService: UserDataService,
    private authService: AuthService, 
    private _route: ActivatedRoute) { 
    
  }

  ngOnInit(): void {
    //this.practices = this.getPracticesList();
    this._route.params.subscribe((params: Params) => {
      console.log(params);
      this.studentId = params.student;
    });

    this.authService.getCurrentUser().then(
      userInfo =>{
        this.collectionOwnerId = userInfo.uid;
        //if(!this.isUserTypeTeacher()) return; //TODO
        
        this.setStudentPracticesList();
        this.setStudentName();
      }
    )

    
  }

  private setStudentName() {
    this.userDataService.findUserInfoById(this.studentId)
      .then(user => this.studentName = user.username)
      .catch(error => console.log(error));
  }

  private setStudentPracticesList() {
  
    this.practiceDataService.getCollectionPracticesSnapshot(this.collectionOwnerId, this.studentId)
      .then(practices => {
        this.practices = practices;
        this.groupedPracticesByDay = this.getPracticesGroupedByCreationDay();
      })
      .catch(error => console.log(error));
  }

  private getPracticesGroupedByCreationDay(): GroupedPracticeList[] {
    let groupedPracticeList: GroupedPracticeList[] = new Array();
    let distinctDays = this.practices
        .map(practice => this.trunc(practice.creationDate))
        .filter(this.onlyUnique);


    distinctDays.forEach(trunkedDate => {
      groupedPracticeList.push({
        day: new Date(trunkedDate),
        practices: this.practices
            .filter(practice => trunkedDate == this.trunc(practice.creationDate))
      })
    });

    groupedPracticeList.sort((list1, list2) => list2.day.getTime() - list1.day.getTime());

    return groupedPracticeList;
  }

  getProgress(correctAnswers: number, totalAnswers: number) {
    return this.calculateResult(correctAnswers, totalAnswers);
  }

  getTotalPractice(){
    this.practices.length;
  }

  getNumberOfDistinctCollectionsPracticed(){
    return this.practices
          .map(practice => practice.collectionId)
          .filter(this.onlyUnique)
          .length;
  }

  getAnswerSuccesRate(){
    let totalAnswers = this.practices
                            .map(practice => practice.totalAnswers)
                            .reduce((a,b) => a + b, 0);
    let correctAnswers = this.practices
                            .map(practice => practice.correctAnswers)
                            .reduce((a,b) => a + b, 0);
    return ((correctAnswers/totalAnswers)*100).toFixed(1);
  }

  getAverageGrade(){
    let totalPoints = this.practices
                .map(practice => (practice.correctAnswers/practice.totalAnswers)*100)
                .reduce((a,b) => a + b, 0);
    return (totalPoints/this.practices.length).toFixed(1);
  }

  getPracticeResultInPercentage(practicedCollectionId: string) {
    return this.practices
        .filter(practice => practice.id == practicedCollectionId)
        .map(practice => this.calculateResult(practice.correctAnswers, practice.totalAnswers))[0];
  }

  getNumberOfAnswersText(total: number) {
    if(total == 1) return "1 respuesta";
    return total+" respuestas";
  }

  private calculateResult(correctAnswers: number, totalAnswers: number): number {
    let fixedResult: number = +((correctAnswers/totalAnswers)*100).toFixed(0);
    return fixedResult;
  }
  

  private trunc(timestamp: number): number {
    let date = new Date(timestamp);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime();
  }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  private getPracticesList(): Practice[] {
    //TO-DO!!
    let practices: Practice[] = new Array();

    let practice1: Practice = {
      collectionId: '1234',
      name: 'Business',
      description: 'FCE - business english',
      correctAnswers: 21,
      totalAnswers: 35,
      creationDate: '1644749124'
    }
    let practice2: Practice = {
      collectionId: '412345',
      name: 'Finances',
      description: 'CAE - business english',
      correctAnswers: 35,
      totalAnswers: 43,
      creationDate: '1644519460'
    }
    let practice3: Practice = {
      collectionId: '123845',
      name: 'Finances',
      description: 'CAE - business english',
      correctAnswers: 15,
      totalAnswers: 43,
      creationDate: '1644489924'
    }
    let practice4: Practice = {
      collectionId: '123456',
      name: 'Finances',
      description: 'CAE - business english',
      correctAnswers: 21,
      totalAnswers: 65,
      creationDate: '1644057924'
    }
    practices.push(practice1);
    practices.push(practice2);
    practices.push(practice3);
    practices.push(practice4);
    return practices;
  }

}

export interface GroupedPracticeList {
  day: Date,
  practices: Practice[]
}
