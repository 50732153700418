import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { first } from 'rxjs/operators';
import { UserDataService } from './user-data.service';


@Injectable(
)
export class AuthService {

  constructor(public afsAuth: AngularFireAuth, public userDataService: UserDataService) {
   }

  async loginEmailUser(email: string, password: string){
    try{
      const result = await this.afsAuth.auth.signInWithEmailAndPassword(email, password);
      return Promise.resolve(result);
    }catch(error){
      return Promise.reject(error);
    }
  }

  async register(email: string, password: string, username: string, age: string, studyCenterType: string){
      this.afsAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          userCredential.user.sendEmailVerification();
          this.userDataService.saveNewUser(userCredential.user.uid, email , username, age, studyCenterType)
            .then(() => 
                {
                  console.log("intentamos resolver promesa");
                  return Promise.resolve(userCredential.user.uid);}
                );
        })
        .catch(error => {
          console.log(error);
          return Promise.reject(error)
        });
  }

  async signOut(){
    try{
      await this.afsAuth.auth.signOut();
    }catch(error){
      //console.log(error);
    }
  }

  getCurrentUser(){
    return this.afsAuth.authState.pipe(first()).toPromise();
  }


}
