import { Component, OnInit, ViewChild } from '@angular/core';
import { CardService } from '../services/card.service';
import { vocablo } from '../models/vocablo';
import { AuthService } from '../services/auth.service';
import { CardWeightManager } from '../services/card-weight-manager'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CollectionProgressComponent } from '../collection-progress/collection-progress.component';
import { CardState } from '../models/card-state';

@Component({
  selector: 'app-card-manager',
  templateUrl: './card-manager.component.html',
  styleUrls: ['./card-manager.component.css']
})
export class CardManagerComponent implements OnInit {
  
  cards: vocablo[];
  currentCard: vocablo = {
    native: '',
    foreign: ''
  }
  loggedUser: string = "";
  collectionOwnerId: string = "";

  practiceUrl: string ="";

  cardDeckId: string = "";
  deckTitle: string;
  deckDescription: string;
  
  showBtn: HTMLInputElement; 
  hideBtn: HTMLInputElement;

  checkall = true;

  cardOrder='';
  orderingOptions = [
    {id: 'weight', name: "Aciertos"},
    {id: 'foreign', name: "Alf. traducción"},
    {id: 'native', name: "Alf. mi idioma"}
  ];

  @ViewChild(CollectionProgressComponent) collectionProgress:CollectionProgressComponent;

  constructor(public cardService: CardService, public authService: AuthService, public cardWeightManager: CardWeightManager,
    private _route: ActivatedRoute,
    private _router: Router
    ) {}

  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      this.setCollectionOwner(params.owner);
      this.cardDeckId = params.collection;
      this.getCollectionCards(params.collection);
    })
    
    this.setTranslationDisplayBtns();
  }

  private setCollectionOwner(ownerId: string) {
    if (ownerId === undefined || ownerId == null) return;
    this.collectionOwnerId = ownerId;
  }

  private getCollectionCards(collectionId: string){
    this.authService.getCurrentUser().then(
      userInfo => {
        this.loggedUser = userInfo.uid;

        if(this.isOwnPractice()) {
          this.setUpCollectionCards(collectionId, this.loggedUser);
        } else {
          this.cardWeightManager.setUserCardStates(collectionId, this.collectionOwnerId, this.loggedUser);
          this.setUpCollectionCards(collectionId, this.collectionOwnerId);
        }
      }
    ).catch(error => {
      this._router.navigate(['/login']);
    })
  }

private setUpCollectionCards(collectionId:string, ownerUserId: string) {
  this.cardService.existsCardDeck(ownerUserId, collectionId).then(
    exists => {
      if(! exists){
        console.log("Colección no encontrada"); // MOSTRAR ALGO EN PANTALLA
        this.notifyNotFoundCollection();
        return;
      }
      this.setPracticeUrl(collectionId);
      this.setAndSubscribeCollection(ownerUserId, collectionId);
      this.cardService.getCardDeckInfo(ownerUserId, collectionId).then(
        deckInfo => this.setCollectionInfoInHeader(deckInfo)
      )
    }
  )
  .catch(error => console.log("component: No existe la collecion"))
}

isOwnPractice(): boolean {
  return this.collectionOwnerId === "" || this.collectionOwnerId == null;
}

private setPracticeUrl(collectionId){
  if(this.isOwnPractice()){
    this.practiceUrl = "/practice/"+collectionId+"/all";
  } else {
    this.practiceUrl = "/practice/"+collectionId+"/all/"+this.collectionOwnerId;
  }
  
}

private setAndSubscribeCollection(ownerUserId, collectionId){
  
  this.getCardStates(ownerUserId, collectionId)
  .then(cardStates => {
    this.cardService.setUserCollection(ownerUserId, collectionId, this.loggedUser, cardStates);
    this.cardService.getCards()
    .subscribe(
        cards => {
          this.cards = cards;
        });
  })
}

private async getCardStates(ownerUserId: string, collectionId: string) {
  if(this.isOwnPractice()) return null;
  
  return await this.cardWeightManager.getCollectionCardStatesSnapshot(ownerUserId, this.loggedUser, collectionId);
}

getCards() {
  return this.cardService.getCards();
}

private setCollectionInfoInHeader(info){
  this.deckTitle = info.title;
  this.deckDescription = info.description;
}

private notifyNotFoundCollection(){
  /*var area = <HTMLDivElement>document.getElementById("card-manager");
  var creator = <HTMLDivElement>document.getElementById("card-creator");
  var list = <HTMLDivElement>document.getElementById("card-list");

  creator.setAttribute("style", "display: none");
  list.setAttribute("style", "display: none");
  area.innerHTML = "<h2>No se ha encontrado ninguna colección</h2>";*/
  window.alert("La dirección no corresponde a ninguna colección");
  this._router.navigate(['/collections']);

}

  private setTranslationDisplayBtns(){
    this.showBtn = <HTMLInputElement>document.getElementById("both");
    this.hideBtn = <HTMLInputElement>document.getElementById("onlyOne");
  }

  addCard = (card: vocablo): void => {
    if (card !== undefined) {
      this.cardService.addCard(card);
    }
  }

  resetCardsLanguageView = (): void => {
    this.forceNativeWordsVisibility();
  }

  public eventCheck(target){
    let cardId = target.srcElement.id
    let tarjeta = <HTMLDivElement> document.getElementById("tarjeta-"+cardId);
    tarjeta.classList.toggle("t-selected");
  }

  public deleteCards(){
    let selectedCards = <HTMLCollection> document.getElementsByClassName("t-selected");
    for(let i=0; i< selectedCards.length; i++){
      this.cardService.deleteCard(this.loggedUser, this.cardDeckId, 
          this.extractCardIdFromText(selectedCards[i].id));
    }
    this.showSelectionBoxes();
  }

  private extractCardIdFromText(card: string){
    var id = card.replace('tarjeta-','');
    return id;
  }

  public showSelectionBoxes(){
    this.uncheckItems();
    let checkboxes = document.getElementsByClassName("checkbox-card");
    //let itemsBtn = document.getElementById("clicked-sel-img");
    let deleteBtn = document.getElementById("delete-btn");
    let coverSelectLayers = document.getElementsByClassName("selection-layer");
    let allCards = document.getElementsByClassName("tarjeta");

    deleteBtn.classList.toggle("hidden");
    //itemsBtn.classList.toggle("hidden");
    this.toggleSelectionBtnState();

    for(let i=0; i< checkboxes.length; i++){
      checkboxes.item(i).classList.toggle("hidden");
      coverSelectLayers.item(i).classList.toggle("hidden");
      allCards.item(i).classList.remove("t-selected");
    }
  }

  public toggleTranslation(){
    this.toggleTranslationBtnState();
    this.toggleNativeWordsVisibility();
  }

  private uncheckItems(){
    if(this.checkall === true){
      this.checkall = false;
    }else{
      this.checkall= true;
    }
  }

  private toggleTranslationBtnState(){
    let translationBtn = document.getElementById("clicked-translation-img");
    let translationBtn2 = document.getElementById("standard-translation-img");
    translationBtn.classList.toggle("hidden");
    translationBtn2.classList.toggle("hidden");
  }

  private toggleSelectionBtnState() {
    let itemsBtn = document.getElementById("clicked-sel-img");
    let itemsBtn2 = document.getElementById("standard-sel-img");
    itemsBtn.classList.toggle("hidden");
    itemsBtn2.classList.toggle("hidden");

  }

  private hideTranslationBtn(){
    let translationBtn = document.getElementById("clicked-translation-img");
    let translationBtn2 = document.getElementById("standard-translation-img");
    translationBtn.classList.add("hidden");
    translationBtn2.classList.remove("hidden");
  }

  private toggleNativeWordsVisibility(){
    let nativeWords = (<HTMLCollectionOf<HTMLSpanElement>>document.getElementsByClassName("native-word"));
    for(let i =0; i<nativeWords.length; i++){
      nativeWords.item(i).classList.toggle("unvisible");
    }
  }

  private forceNativeWordsVisibility(){
    let nativeWords = (<HTMLCollectionOf<HTMLSpanElement>>document.getElementsByClassName("native-word"));
    for(let i =0; i<nativeWords.length; i++){
      nativeWords.item(i).classList.toggle("unvisible", false);
    }
    this.hideTranslationBtn();
  }

  openLevelsView() {
    if(this.cards.length == 0) return;

    let levelsView = document.getElementById("levels-area");
    if(this.isViewTabAlreadyActive(levelsView)) return;

    this.toggleBodyView();
    let cardsView = document.getElementById("card-list");
    cardsView.classList.toggle('display-none', true);

    levelsView.classList.toggle('display-none', false);

    setTimeout(()=>{                         
      this.collectionProgress.setCollectionProgress();
    }, 500);
  }

  openCardsView() {
    let cardsView = document.getElementById("card-list");
    if(this.isViewTabAlreadyActive(cardsView)) return;

    this.toggleBodyView();
    cardsView.classList.toggle('display-none', false);

    let levelsView = document.getElementById("levels-area");
    levelsView.classList.toggle('display-none', true);
  }
  private toggleBodyView() {
    let levelsMenuItem = document.getElementById("cards-view");
    let cardsMenuItem = document.getElementById("levels-view");
    levelsMenuItem.classList.toggle("active");
    cardsMenuItem.classList.toggle("active");
  }

  private isViewTabAlreadyActive(item){
    if(! item.classList.contains("display-none")) return true;
    return false;
  }

}
