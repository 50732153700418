import { Injectable } from '@angular/core';
import { AuthErrorCodes } from '../models/auth-error-codes';

@Injectable()
export class AuthErrors {

    WEAK_PASSWORD: AuthErrorCodes = {code: 'auth/weak-password', message: 'La contraseña indicada es demasiado débil. Utilice una con al menos 6 caracteres'}
    ALREADY_IN_USE_EMAIL: AuthErrorCodes = {code: 'auth/email-already-in-use', message: 'El email ya está en uso'}
    INVALID_EMAIL_REG_LOGIN: AuthErrorCodes = {code: 'auth/invalid-email', message: 'El email indicado no es válido'}

    GENERIC_LOGIN: string = "El email o la contraseña no son correctos"
    INVALID_PSW_LOGIN: AuthErrorCodes = {code: 'auth/wrong-password', message: this.GENERIC_LOGIN }

    errorCodes: AuthErrorCodes[] = [];

    constructor(){
        this.errorCodes = [this.WEAK_PASSWORD, this.INVALID_EMAIL_REG_LOGIN, this.ALREADY_IN_USE_EMAIL, 
            this.INVALID_PSW_LOGIN];
    }

    getMessage(inputcode: string){
        for(let errorCode of this.errorCodes){
            if(errorCode.code === inputcode) return errorCode.message;
        }
        return null;
    }
}