import { Component, OnInit } from '@angular/core';
import { CardCollection } from '../models/card-collection';
import { CardService } from '../services/card.service';
import { AuthService } from '../services/auth.service';
import { UserDataService } from '../services/user-data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserInfo } from '../models/user-info';

@Component({
  selector: 'app-card-collections',
  templateUrl: './card-collections.component.html',
  styleUrls: ['./card-collections.component.scss']
})
export class CardCollectionsComponent implements OnInit {

  user: string = "";
  userId: string = "";

  student: UserInfo = null;

  collectionList: CardCollection[];

  newCollection: CardCollection = {
    title: '',
    description: ''
  };
  collectionEditor: HTMLDivElement;
  isEditorShown: boolean;

  constructor(public cardService: CardService, public authService: AuthService, public userDataService: UserDataService, private _router: Router) {}

  ngOnInit(): void {
    this.collectionEditor = <HTMLDivElement>document.getElementById("collection-editor-container");
    this.authService.getCurrentUser().then(
      userInfo => {
      this.user = userInfo.email; // esto habra que quitar
      this.userDataService.findUserInfoById(userInfo.uid).then(
        result => this.student = result
      );
      this.cardService.getCardDecks().subscribe(collections => {
        this.collectionList = collections;
        this.initializeEditorFlag(collections);
      } );
    })
    .catch(() => {
      this._router.navigate(['/login']);
    })
    
  }
  private initializeEditorFlag(collecionLength){
    if(collecionLength === 0){
      this.isEditorShown = true;
    } else{
      this.isEditorShown = false;
    }
  }
  showCollectionEditor(){
    this.collectionEditor.style.display = "block";
    this.isEditorShown = true;
  }

  addNewCollection(){
    if(! this.isEditorInputEmpty()){
      let collection: CardCollection = {
        title: this.newCollection.title,
        description: this.newCollection.description,
        creationDate: this.getCurrentDate().toDateString()
      };
      this.cardService.addCardDeck(collection);
      this.hideCollectionEditor();
      this.clearEditor();
    }
  }

  private isEditorInputEmpty(){
    var completed = false;
    if(this.newCollection.title === ""){
      this.adviceUncompleteEditorTitle();
      completed = true;
    }
    if(this.newCollection.description === ""){
      this.adviceUncompleteEditorDescription();
      completed = true;
    }
    return completed;
  }

  private getCurrentDate(){
    return new Date;
  }
  hideCollectionEditor(){
    this.collectionEditor.style.display= "none";
    this.isEditorShown = false;
    this.clearEditor();
  }
  isCollectionEditorShown(){
    return this.isEditorShown;
  }

  private clearEditor(){
    this.newCollection.title= '';
    this.newCollection.description= '';
    let titleInput = <HTMLInputElement> document.getElementById("collection-title-input");
    titleInput.classList.remove("uncomplete");
    let descriptionInput = <HTMLInputElement> document.getElementById("collection-desc-input");
    descriptionInput.classList.remove("uncomplete");
    this.resetEditorStyle();
  }
  private adviceUncompleteEditorTitle(){
    let titleInput = <HTMLInputElement> document.getElementById("collection-title-input");
    titleInput.classList.add("uncomplete");
  }
  private adviceUncompleteEditorDescription(){
    let descriptionInput = <HTMLInputElement> document.getElementById("collection-desc-input");
    descriptionInput.classList.add("uncomplete");
  }
  public resetEditorStyle(){
    let titleInput = <HTMLInputElement> document.getElementById("collection-title-input");
    titleInput.classList.remove("uncomplete");
    let descriptionInput = <HTMLInputElement> document.getElementById("collection-desc-input");
    descriptionInput.classList.remove("uncomplete");
  }

  collectionMenuOnClick(event, collection){
    let collectionId = collection.id;
    let optionArea = <HTMLDivElement> document.getElementById("opt-area-"+collectionId);
    optionArea.classList.toggle("hide");
  }
  collectionMenuOnClickOutside($event, collection){
    if(collection === null) return;
    let optionArea = <HTMLDivElement> document.getElementById("opt-area-"+collection.id);
    optionArea.classList.toggle("hide", true);
  }

  public deleteCardDeck(event){
    let collectionId = this.getCollectionIdFromInputClass(event.srcElement);
    this.authService.getCurrentUser().then(userInfo => {
      this.cardService.deleteDeck(userInfo.uid, collectionId);
    })
  }

  private getCollectionIdFromInputClass(element){
    if(element.classList[0] === "btn") return element.classList[1];
    return element.classList[0];
  }
  public hideAllOptionAreas(){
    let collectionsOptionAreas = document.getElementsByClassName("coll-option-area");

    for(let i = 0; i< collectionsOptionAreas.length; i++){
      collectionsOptionAreas[i].classList.toggle("hide", true)
    }
  }

closeAppInfo(){
  let appInfoModal = document.getElementById("app-info-modal");
  let darkLayer = document.getElementById("dark-layer");

  appInfoModal.classList.toggle("display-none",true);
  darkLayer.classList.toggle("display-none",true)
}
showAppInfo(){
  let appInfoModal = document.getElementById("app-info-modal");
  let darkLayer = document.getElementById("dark-layer");

  appInfoModal.classList.toggle("display-none",false);
  darkLayer.classList.toggle("display-none",false)
}

logout(){
  this.authService.signOut().then(
    () => this._router.navigate(['/'])
  )
}

openAcademyView() {
  //if(this.cards.length == 0) return;

  let academyView = document.getElementById("academy-collections-ctr");
  if(this.isViewTabAlreadyActive(academyView)) return;

  this.toggleBodyView();
  let ownCollectionsView = document.getElementById("collections-list");
  ownCollectionsView.classList.toggle('display-none', true);

  academyView.classList.toggle('display-none', false);
}

openCollectionsView() {
  let owCollectionsView = document.getElementById("collections-list");
  if(this.isViewTabAlreadyActive(owCollectionsView)) return;

  this.toggleBodyView();
  owCollectionsView.classList.toggle('display-none', false);

  let academyView = document.getElementById("academy-collections-ctr");
  academyView.classList.toggle('display-none', true);
}

private isViewTabAlreadyActive(item){
  if(! item.classList.contains("display-none")) return true;
  return false;
}

private toggleBodyView() {
  let academyCollectionsMenuItem = document.getElementById("academy-view");
  let ownCollectionsMenuItem = document.getElementById("own-collections-view");
  academyCollectionsMenuItem.classList.toggle("active");
  ownCollectionsMenuItem.classList.toggle("active");
}

}
