<div id="home-container">
    <div id="home-nav-cntr">
        <div id="home-nav">
            <a href="/">
                <div id="logo">
                    <img *ngIf="!isMobileDevice" style="width: 55%;" alt="vokablo-complete-logo" src="/assets/vocablo-icon/vocablo-word-blue/vocablo-word-blue.png">
                    <img *ngIf="isMobileDevice" alt="vokablo-single-logo" src="../../assets/vocablo-single-white/vocablo-single-bluexs.png">
                </div>
            </a>
            <div id="nav-actions">
                <a *ngIf="!isUserLogged" href="/auth/login"><h3>Iniciar sesión</h3></a>
                <a *ngIf="isUserLogged" href="/collections"><h3>Mi área</h3></a>
            </div>
        </div>
    </div>
    <div id="welcome-section" class="section container-fluid">
        <div id="welcome-section-content" class="row">
            <div id="intro-txt-area" class="col-12 col-md-6">
                <h2>Potencia el estudio de vocabulario de idiomas en tu academia</h2>
                <h4>Vokablo posibilita a los profesores ofrecer un aprendizaje más efectivo y divertido a sus alumnos a través de flashcards</h4>
                <div class="centered-txt-container">
                    <a *ngIf="!isUserLogged" href="/auth/login" id="begin-btn" class="btn">Acceder</a>
                    <a *ngIf="isUserLogged" href="/collections" id="begin-btn" class="btn">Comenzar</a>
                    <a href="#trial-form" class="btn" id="trial-link">Solicita prueba gratuita</a>
                </div>
            </div>
            <div id="intro-img-area" class="col-12 col-md-6">
                <img src="/assets/student-graphic_2.png">
            </div>
        </div>
    </div>
    <div class="container-fluid" id="why-vokablo">
        <div class="row section">
            <h3 class="col-12" id="why-title">¿Por qué Vokablo?</h3>
            <div class="col-md-6 col-12 l-side">
                <div class="txt-col">
                    <h4>Aprender jugando</h4>
                    <div>
                        Aprender vocabulario de una forma divertida motiva a los alumnos a <strong>tener constancia</strong> en el estudio, lo que es clave para retener las palabras en la memoria a largo plazo.
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 r-side">
                <div class="img-container">
                    <img src="../../assets/practice-example.png" class="app-screenshot" id="practice-example">
                </div>
            </div>
        </div>
        <div class="row section">
            <div class="col-md-6 col-12 r-side">
                <div class="img-container">
                    <img src="../../assets/responsive.png">
                </div>
            </div>
            <div class="col-md-6 col-12 l-side">
                <div class="txt-col" id="responsive-txt">
                    <h4>Web App adaptada a todos los dispositivos</h4>
                    <div>
                        La aplicación web ha sido diseñada para ser <strong>accesible</strong> en cualquier tipo de dispositivo
                    </div>
                </div>
            </div>
        </div>
        <div class="row section">
            <div class="col-md-6 col-12 l-side">
                <div class="txt-col" id="responsive-txt">
                    <h4>Seguimiento del progreso de los alumnos</h4>
                    <div>
                        Los profesores cuentan con un área en la aplicación que permite el <strong>seguimiento de las prácticas y evaluaciones</strong> obtenidos por sus alumnos
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 r-side">
                <div class="img-container">
                    <img src="../../assets/student-overview_2.png" id="student-overview-img" class="app-screenshot">
                </div>
            </div>
        </div>
        <div class="row section">
            <div class="col-md-6 col-12 r-side">
                <div class="img-container">
                    <img src="../../assets/logo-example.png">
                </div>
                <!-- <div class="img-container">
                    <img src="../../assets/collection-with-academy-logo-detail.png" class="app-screenshot">
                </div> -->
            </div>
            <div class="col-md-6 col-12 l-side">
                <div class="txt-col" id="responsive-txt">
                    <h4>Refuerza la marca de tu academia</h4>
                    <div>
                        Permite ofrecer a los usuarios la <strong>experiencia visual de una plataforma propia</strong>, con el logotipo o distintivos de la academia
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="trial-form" class="container-fluid">
        <div id="form-container" class="row">
            <div class="col-md-6 col-12">
                <div id="trial-description-col">
                    <h3>Solicitar una prueba gratuita de Vokablo</h3>
                    <div>Rellena el formulario y descubre todo el potencial de la plataforma de forma gratuita</div>
                    <h4>¿Qué puedes esperar?</h4>
                    <div id="what-to-expect" class="row">
                        <div class="col-1"><img src="../../assets/check-list.png"></div>
                        <div class="col-11 item">Prueba gratuita personalizada según los intereses y necesidades de tu academia</div>
                        <div class="col-1"><img src="../../assets/check-list.png"></div>
                        <div class="col-11 item">Cómo funciona nuestra plataforma y herramientas disponibles</div>
                        <div class="col-1"><img src="../../assets/check-list.png"></div>
                        <div class="col-11 item">Precios adaptados a academias o clases de todos los tamaños</div>
                    </div>          
                </div>
            </div>

            <div id="form-box-container" class="col-md-6 col-12 container-fluid">
                <div id="form-box">
                    <form (ngSubmit)="submitFreeTrialRequest()" [formGroup]="form" method="post">
                        <div id="form-fields" class="row container-fluid">
                            <input [ngClass]="{'invalid': this.contactName.invalid && this.contactName.touched}" class="col-5 input" type="text" id="contact-name" placeholder="* Nombre" name="name" [formControl]="contactName" required>
                            <input class="col-5 input" type="text" id="contact-lastname" placeholder="Apellidos" name="lastname" [formControl]="contactLastName">
                            <input [ngClass]="{'invalid': this.contactEmail.invalid && this.contactEmail.touched}" class="col-12 input" type="email" id="contact-email" placeholder="* email" name="email" [formControl]="contactEmail" required>
                            <input [ngClass]="{'invalid': this.contactPhoneNumber.invalid && this.contactPhoneNumber.touched}" class="col-12 input" type="text" id="contact-phone" placeholder="Teléfono de empresa" name="contact-phone" [formControl]="contactPhoneNumber">
                            <input [ngClass]="{'invalid': this.academyName.invalid && this.academyName.touched}" class="col-12 input" type="text" id="academy-name" placeholder="* Nombre de la academia" name="academy-name" [formControl]="academyName" required>
                            <select class="col-12 input" name="lead-position" [formControl]="contactPersonPosition">
                                <option value="" disabled selected>* ¿Cuál es tu posición?</option>
                                <option *ngFor="let position of academyLeadPosition" [value]="position">{{position}}</option>
                            </select>
                            <select [ngClass]="{'invalid': this.academySize.invalid && this.academySize.touched}" class="col-12 input" name="students-number" [formControl]="academySize" required>
                                <option value="" disabled selected>* Estudiantes</option>
                                <option *ngFor="let size of academyStudentsNumber" [value]="size">{{size}}</option>
                            </select>
                        </div>
                        <div id="form-btn" class="row">
                            <input class="col-8" type="submit" value="Solicitar una prueba gratuita">
                        </div>
                        <p *ngIf="this.submitted && !this.form.valid" class="form-feedback error">
                            Alguno de los campos rellenados no es válido
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <footer class="section container-fluid">
        <div id="contact">
            Para cualquier duda o sugerencia de mejora no dudes en contactar al correo <a href="mailto:vokablo.academias@vokablo.com">vokablo.academias@vokablo.com</a>
            <br> ¡GRACIAS!
        </div>
        <div class="row" id="footer-links">
            <a href="#" class="col-6 col-md-3">
                <img alt="vokablo-single-logo" src="../../assets/vocablo-single-white/vocablo-single-bluexs.png">
            </a>
            <a href="#" class="col-6 col-md-3">Términos y condiciones</a>
            <a href="#" class="col-6 col-md-3">Sobre nosotros</a>
            <a href="mailto:vokablo.app@gmail.com" class="col-6 col-md-3">Contacta</a>
        </div>
    </footer>
</div>