import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import { HttpClientModule } from '@angular/common/http';

//import { AppRoutingModule } from './app-routing.module';
import { routing, appRoutingProviders} from './app-routing';
import { AppComponent } from './app.component';
import { CardManagerComponent } from './card-manager/card-manager.component';

import { AngularFireModule } from 'angularfire2'
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule} from 'angularfire2/firestore'
import { environment } from '../environments/environment';
import { AuthenticationComponent } from './authentication/authentication.component'
import { AuthService } from './services/auth.service';
import { UserDataService } from './services/user-data.service';
import { PracticeComponent } from './practice/practice.component';
import { HomeComponent } from './home/home.component';
import { AuthErrors } from './authentication/auth-errors';
import { CardCollectionsComponent } from './card-collections/card-collections.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { CardWeightManager } from './services/card-weight-manager';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SettingsComponent } from './settings/settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './modal/modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AcademyCollectionsComponent } from './academy-collections/academy-collections.component';
import { CardCreatorComponent } from './card-creator/card-creator.component';
import { CollectionProgressComponent } from './collection-progress/collection-progress.component';
import { StudentsComponent } from './students/students.component';
import { HeaderComponent } from './header/header.component';
import { MetricsComponent } from './metrics/metrics.component';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    CardManagerComponent,
    AuthenticationComponent,
    PracticeComponent,
    HomeComponent,
    CardCollectionsComponent,
    OrderByPipe,
    SettingsComponent,
    ModalComponent,
    AcademyCollectionsComponent,
    CardCreatorComponent,
    CollectionProgressComponent,
    StudentsComponent,
    HeaderComponent,
    MetricsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule
  ],
  providers: [ 
    appRoutingProviders, AuthService, AuthErrors, UserDataService, CardWeightManager, {provide: LOCALE_ID, useValue:'es'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
