import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, DocumentData } from 'angularfire2/firestore' 
import { vocablo } from '../models/vocablo';
import { CardState } from '../models/card-state';
import { CardCollection } from '../models/card-collection';
import { AuthService } from './auth.service';
import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root',
})
export class CardService {

  firebaseVocabloCollection: AngularFirestoreCollection<vocablo>;
  cards: Observable<vocablo[]>;
  cardDoc: AngularFirestoreDocument<vocablo>;

  firebaseCardDeckCollection: AngularFirestoreCollection<CardCollection>;
  cardCollections: Observable<CardCollection[]>;
  deckDoc: AngularFirestoreDocument<CardCollection>;

  firebaseAcademyCardDeckCollection: AngularFirestoreCollection<CardCollection>;
  academyCollections: Observable<CardCollection[]>;
  academyDeckDoc: AngularFirestoreDocument<CardCollection>;

  constructor(public firebase: AngularFirestore, public authService: AuthService) {
    this.authService.getCurrentUser().then(
      userInfo =>
      {
        this.setUserCardDeckCollection(userInfo.uid);
      })
      .catch(() => console.error("No user is authenticated."))
  }

  public setUserCollection(collectionOwnerId: string, collectionId: string, loggedUserId: string, cardStates: CardState[]){
    this.firebaseVocabloCollection = this.firebase.collection(`users/${collectionOwnerId}/cardCollections/${collectionId}/cards`);
    this.cards = this.firebaseVocabloCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a=>{
        const data = a.payload.doc.data() as vocablo;
        data.id = a.payload.doc.id;
        return data;
      })
    }))
    .pipe(
      map(tarjs => {
        if(collectionOwnerId == loggedUserId) return tarjs.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
        return tarjs.map( card => this.mapCardState(card, cardStates));
      })
    );
  }

  private mapCardState(card: vocablo, cardStates: CardState[]): vocablo {
      let state = cardStates.find(state => state.id == card.id);

      if(state == null) {
        card.weight = 0;
      } else {
        card.weight = state.progress;
      }
    
      return card;
  }

  private setUserCardDeckCollection(userId: string){
    this.firebaseCardDeckCollection = this.firebase.collection(`users/${userId}/cardCollections`);
    this.cardCollections = this.firebaseCardDeckCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a=>{
        const data = a.payload.doc.data() as CardCollection;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  public setAcademyDeckCollection(teacherId: string) {
    this.firebaseAcademyCardDeckCollection = this.firebase.collection(`users/${teacherId}/cardCollections`);
    this.academyCollections = this.firebaseAcademyCardDeckCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a=>{
        const data = a.payload.doc.data() as CardCollection;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  getCardDecks(){
    return this.cardCollections;
  }

  addCardDeck(deck: CardCollection){
    this.firebaseCardDeckCollection.add(deck);
  }

  getAcademyDecks() {
    return this.academyCollections;
  }

  async existsCardDeck(userId: string, collectionId: string) {
    const deckDoc = this.firebase.collection(`users/${userId}/cardCollections`).doc(collectionId);
    try{
      const result = await deckDoc.ref.get();
      return Promise.resolve(result.exists);
    }catch(error){
      return Promise.reject(false);
    }
  }

  async getCardDeckInfo(userId: string, collectionId: string){
    const deckDoc = this.firebase.collection(`users/${userId}/cardCollections`).doc(collectionId);
    try{
      let cardDeck = await (await deckDoc.ref.get()).data();
      return this.mapCardDeck(cardDeck);
    }catch(error){
      //console.log(error);
    }
  
  }

  public getCards(){
    return this.cards;
  }

  addCard(card: vocablo){
    card.creationDate = firebase.firestore.FieldValue.serverTimestamp();
    card.weight = 0;
    this.firebaseVocabloCollection.add(card);
  }

  deleteCard(userId: string, collectionId: string, cardId: string){
    this.cardDoc = this.firebase.doc(`users/${userId}/cardCollections/${collectionId}/cards/${cardId}`);
    this.cardDoc.delete();
  }

  deleteDeck(userId: string, deckId: string){
    this.deckDoc = this.firebase.doc(`users/${userId}/cardCollections/${deckId}`);
    this.deckDoc.delete();
  }

  updateCardWeight(card: vocablo, userId:string, collectionId: string) {
    var cardToUpdateRef = this.firebase.doc(`users/${userId}/cardCollections/${collectionId}/cards/${card.id}`);
    
    cardToUpdateRef.update({
      weight: card.weight
    }).then(() => {}
    ).catch(error => {
      console.log(error);
    })
  }

  public async getCardListSnapshot(userId: string, collectionId: string){
    var cardsRef = this.firebase.collection(`users/${userId}/cardCollections/${collectionId}/cards`).ref;
    
    try{
      var list: DocumentData [];
      list = await (await cardsRef.get()).docs
      return (this.mapSnapshotCards(list));
    }catch(error){
      console.log(error);
    }
  }

  private mapSnapshotCards(list: DocumentData []){
    let practiceCards: vocablo[] = [];
    for(let i = 0; i<list.length; i++){
      if(practiceCards.push(this.mapCardVO(list[i])) === list.length) return practiceCards;
    }
  }

  private mapCardVO(cardVO: DocumentData) {
    let card: vocablo;
    card = {
      foreign: cardVO.data().foreign,
      native: cardVO.data().native,
      weight: cardVO.data().weight,
      id: cardVO.id
    }
    return card;
  }

  private mapCardDeck(deckData: DocumentData){
    let deck: CardCollection = deckData;
    return deck;
  }
}
