import { Component, OnInit, Input } from '@angular/core';
import { vocablo } from '../models/vocablo';

@Component({
  selector: 'app-card-creator',
  templateUrl: './card-creator.component.html',
  styleUrls: ['./card-creator.component.scss']
})
export class CardCreatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() public create: (param: vocablo) => void;
  @Input() public resetCardsLanguageView: () => void;

  currentCard: vocablo = {
    native: '',
    foreign: ''
  }

  onSubmit(){
    if(this.isCurrentCardFilled){
      this.create(this.currentCard);
      this.clearFields();
      this.resetCardsLanguageView();
    }else{
      alert("Debes rellenar los campos de la tarjeta");
    }
  }

  toggleEditor(){
    let editor = document.getElementById("card-creator");
    let tab = document.getElementById("creator-tab");
    
    editor.classList.toggle("collapse-editor");
    editor.classList.toggle("shown");
    tab.classList.toggle("hidden");
  }

  private clearFields(){
    this.currentCard.native='';
    this.currentCard.foreign='';
  }

  private isCurrentCardFilled(){
    if(this.currentCard.native != '' && this.currentCard.foreign != '') return true;
    return false;
  }
}
