<div id="practice-container">
    <div id="head" class="row">
        <a href="{{this.buildCollectionPageUrl()}}" class="col-2"><img src="/assets/cross2.png"></a>
        <div id="progress-rate" class="col-10">
            <h4 *ngIf="this.total">{{this.progress}}/{{this.total}}</h4>
            <h4 *ngIf="!this.total">Cargando...</h4>
        </div>
    </div>
    <div class="scoreboard">
        <div id="practice-progress"></div>
    </div>
    <div id="practice-area" class="col-md-8">
        <div class="question-card" [ngClass]="{'preload': !currentCard }">
            <h3 id="native-word">{{ this.currentCard?.native }}</h3>
        </div>
        <form (ngSubmit)="onAnswer()">
            <input type = "text" id="answer-area" [(ngModel)]="currentAnswer" name="answer" autocomplete="off">
            <input type="submit" id="answerBtn" value="Responder" class="btn guardar">
        </form>
    </div>
    <div id="feedback-coloured-area" class="display-none">
        <div id="feedback-area" class="row">
            <div id="answer-result" class="error col-md-8">
                <h3 id="result-message">Respuesta correcta</h3>
                <h4 id="correct-answer" class="error">{{this.currentCard?.native}} = {{this.currentCard?.foreign}}</h4>
            </div>
            <div id="continue-btn-area" class="col-md-4">
                <input id="feedback-continue-btn" class="btn error" type="submit" value="Continuar" (click)="getNextCard()">
            </div>
        </div>
    </div>
    <div id="practice-summary-area" class="display-none">
        <div id="summary-header" class="row">
            <div id="closing-area-cross" class="col-12"><a href="/collections" id="closing-cross"><img src="/assets/cross2.png"></a></div>        
            <h3 class="col-md-8 col-12">Resumen de la práctica</h3>
            <div id="practice-score-area" class="col-md-3 col-12">
                <h2 id="final-score" class="text-nowrap"></h2>
            </div>
        </div>
        <div id="results-area" class="row">
            <div *ngFor="let card of practiceCards" class="summary-card-cntr col-md-3 col-sm-6 col-12">
                <div class="summary-card success-{{card.success}}">
                    <span class="native-word">{{card.native}}<br></span>
                    <span class="foreign-word">{{card.foreign}}<br></span>
                    <span class="answer success-{{card.success}}">R: {{card.answer}}</span>
                </div>
            </div>
        </div>
        <div id="practice-summary-actions" class="row">
            <input id="try-again-btn" class="btn col-4" (click)="reloadPractice()" type="submit" value="Repetir">
            <a href="{{this.buildCollectionPageUrl()}}" id="finish-btn" class="col-4 btn">Finalizar</a>
        </div>
    </div>
</div>
