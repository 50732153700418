import { Component, OnInit, ModuleWithProviders } from '@angular/core';
import {AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {AuthErrors} from './auth-errors';
import { UserDataService } from '../services/user-data.service';
import { UserInfo } from '../models/user-info';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  providers: [AuthService]
})
export class AuthenticationComponent implements OnInit {

  private authMode: string; // register - login
  private authModeParamOptions: string[] = ["register", "login"];

  academyToJoinId: string;
  academy: UserInfo;

  ages: string[] = [];
  studyCenterTypeOptions: string[] = ["Colegio", "Universidad", "Academia", "Clases particulares", "Por mi cuenta"];

  loginInfo: any = {
    email: '',
    password: ''
  }

  registerInfo: any = {
    email: '',
    password: '',
    username: '',
    age: '',
    studyCenterType: ''
  }

  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private _router: Router,
    private _route: ActivatedRoute,
    private authErrors: AuthErrors
    ) { 
  }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      if(!this.authModeParamOptions.includes(params.authoption)){
        console.log("parametro de auth incorrecto");
      }
      this.authMode = params.authoption;
      
      if(params.academy) {
        this.academyToJoinId = params.academy;
        this.findAcademy();
        console.log(this.academyToJoinId);
      }
    })

    this.fillRegisterAgeOptions();
  }

  isRegisterOptionActive() {
    return this.authMode == this.authModeParamOptions[0];
  }

  isLoginOptionActive() {
    return this.authMode == this.authModeParamOptions[1];
  }

  private fillRegisterAgeOptions(){
    for(var i = 2018; i>=1918; i--){
      this.ages.push(i.toString());
    }
  }

  showLogin(){
    this.clearRegisterFeedbackMessage();
    this.clearLoginInfo();
    this.authMode = this.authModeParamOptions[1];
  }
  showRegister(){
    this.clearLoginFeedbackMessage();
    this.authMode = this.authModeParamOptions[0];
  } 

  onRegister() {
    this.clearRegisterFeedbackMessage();
    if(! this.isRegisterFormFilled()) {
      this.displayRegisterFeedbackMessage("Completa los campos para efectuar el registro", false); return;
    }  
    
    this.authService.register(this.registerInfo.email, this.registerInfo.password, this.registerInfo.username, this.registerInfo.age, this.registerInfo.studyCenterType)
    .then(
      userUid => {
        console.log(userUid);
        //this.userDataService.updateUserAcademyInfo(userInfo, this.academy.id, {});
        this.displayRegisterFeedbackMessage("Te has registrado correctamente. Por favor, debes verificar tu email en el correo que te hemos enviado.", true);
      }
    ).catch(
      error => {
        console.log(error.code);
        this.displayRegisterFeedbackMessage(this.authErrors.getMessage(error.code), false);
      }
    )
  }

  private setUserAcademyRelation(userId: string) {
    console.log("intento de actualizar relación alumno - academia");
    console.log(userId);
    this.userDataService.updateUserAcademyInfo(userId, this.academy.id, {});
  }

  async onLogin(){
    this.clearLoginFeedbackMessage();
    if(! this.isLoginFormFilled()){
      this.displayLoginFeedbackMessage("Completa los campos para acceder a tu cuenta", false); return;
    }

    let usuario: firebase.auth.UserCredential = null;
    try{
      this.showLoggingInImg();
      usuario = await this.authService.loginEmailUser(this.loginInfo.email, this.loginInfo.password);
      if(usuario.user.emailVerified) this._router.navigate(['/collections']);
    }catch(error){
      this.showLoggingInImg();
      console.log(error.code);
      this.displayLoginFeedbackMessage(this.authErrors.getMessage(error.code), false); return;
    }
    
    if(! usuario.user.emailVerified){
      this.authService.signOut();
      this.showLoggingInImg();
      this.displayLoginFeedbackMessage("Por favor, verifique su cuenta en el correo que le hemos enviado", false);
      return;
    }
  }

  onExit(){
    this._router.navigate(['/']);
  }

  private findAcademy() {
    this.userDataService.findTeacherById(this.academyToJoinId).then(
      entity => {
        if(entity != null) {
          this.academy = entity;
          // mostrar info de academia
          // bloquear input academia + cambiar color
          // desbloquear botón de registro
        }
      }
    )
    .catch(error => {
      console.log("Fallo en la busqueda de la academia");
      // sacar modal de aviso -> redirigir a register normal sin academia
      this.academy = null;
    });
  }

  private showLoggingInImg(){
    let msg = document.getElementById("entering-app-gif");
    msg.classList.toggle("display-none");
  }

  private clearLoginInfo(){
    this.loginInfo.email = '';
    this.loginInfo.password = '';
  }

  private isLoginFormFilled(){
    if(this.loginInfo.email === '' || this.loginInfo.password === '') return false;
    return true;
  }

  private isRegisterFormFilled(){
    if(this.registerInfo.email === '' || this.registerInfo.password === '' || this.registerInfo.username === '' 
    || this.registerInfo.age ==='' || this.registerInfo.studyCenterType === '') return false;
    return true;
  }
 
  private displayRegisterFeedbackMessage(message: string, success: boolean){
    var userField = (<HTMLParagraphElement>document.getElementById("register-feedback-msg"));
    userField.innerHTML = userField.innerHTML+"\n"+message;
    if(success == true){
      userField.style.color = "green";
    }
  }
  private clearRegisterFeedbackMessage(){
    var userField = (<HTMLParagraphElement>document.getElementById("register-feedback-msg"));
    userField.innerHTML = '';
  }
  private displayLoginFeedbackMessage(message: string, success: boolean){
    var userField = (<HTMLParagraphElement>document.getElementById("login-feedback-msg"));
    userField.innerHTML = userField.innerHTML+"\n"+message;
    if(success == true){
      userField.style.color = "green";
    }
  }
  private clearLoginFeedbackMessage(){
    var userField = (<HTMLParagraphElement>document.getElementById("login-feedback-msg"));
    userField.innerHTML = '';
  }
}
