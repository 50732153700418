    <div class="col-12" id="progress-data">
        <h3>{{collectionProgres}}%</h3>
        <h4>completada</h4>
    </div>
    <div class="rocket-progress col-3 col-md-2">
        <div class="progress-line"></div>
        <div class="moon" (click)=setCollectionProgress()><img src="../../assets/moon.png"></div>
        <div id="rocket"><img src="../../assets/rocket(2).png"></div>
    </div>
    <div id="level-boxes-area" class="col-7 col-md-10">
        <div *ngFor="let level of levelsConfig" class="level-item {{level.colorclass}} row">
            <div class="level-label col-6">
                <div class="level-name">{{level?.name}}</div>
                <div class="description-label"><li>{{level?.label}}</li></div>
            </div>
            <div class="level-stats col-4">
                <div class="level-nr-words">{{this.getNumberOfCardsInLevel(level.id)}}</div>
                <div>palabras</div>
            </div>
            <div class="practice-level col-2" [ngClass]="{'display-none': this.getNumberOfCardsInLevel(level.id) == 0}">
                <a href="{{getPracticeRoute(level?.id)}}" *ngIf="this.getNumberOfCardsInLevel(level.id) > 0">
                </a>
                <img src="/assets/train.png">
            </div>
        </div>
    </div>