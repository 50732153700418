import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { CardManagerComponent } from './card-manager/card-manager.component';
import { PracticeComponent } from './practice/practice.component';
import { HomeComponent } from './home/home.component';
import { CardCollectionsComponent } from './card-collections/card-collections.component';
import { SettingsComponent } from './settings/settings.component';
import { StudentsComponent } from './students/students.component';
import { HeaderComponent } from './header/header.component';
import { MetricsComponent } from './metrics/metrics.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'auth', component: AuthenticationComponent },
    { path: 'auth/:authoption', component: AuthenticationComponent },
    { path: 'auth/:authoption/academy/:academy', component: AuthenticationComponent },
    { path: 'header', component: HeaderComponent }, //Esto se va a poder eliminar
    { path: "collections", component: CardCollectionsComponent },
    { path: 'mycards/:collection', component: CardManagerComponent },
    { path: 'mycards/:collection/:owner', component: CardManagerComponent },
    { path: 'practice/:collection/:group', component: PracticeComponent},
    { path: 'practice/:collection/:group/:owner', component: PracticeComponent},
    { path: 'settings', component: SettingsComponent},
    { path: 'students', component: StudentsComponent},
    { path: 'students/:student/metrics', component: MetricsComponent },
    { path: '**', component: HomeComponent } //Esta seria la ruta 404, en caso de que no encuentre ninguna. Debe definirse la última.
];

export const  appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);