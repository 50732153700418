import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input () loggedUserId: string;
  @Input () email: string;
  @Input () settingsAvailable: boolean;
  @Input () logoutAvailable: boolean;

  constructor(private _router: Router, public authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.signOut().then(
      () => this._router.navigate(['/'])
    )
  }
}
