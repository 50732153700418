import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData } from 'angularfire2/firestore' 
import { vocabloPractice } from '../models/vocabloPractice';
import { Practice } from '../models/practice';
import { CardService } from './card.service';
import { CollectionProgressComponent } from '../collection-progress/collection-progress.component';
import { CardCollection } from '../models/card-collection';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PracticeDataService {

  userPracticesCollection: AngularFirestoreCollection<Practice>;
  practices:Practice[];

  constructor(public firebase: AngularFirestore, public cardService: CardService) { 
    
  }

  private setAcademyUserPracticesCollection(ownerId: string, userId: string, collectionId: string){
    this.userPracticesCollection = this.firebase.collection(`academyStudentsPractices/${ownerId}/students/${userId}/practices`);
  }

  public getStudentPractices(ownerId: string, userId: string, collectionId: string) {

  }


  public addNewUserPracticeResult(ownerId: string, userId: string, collectionId: string, practiceCards: vocabloPractice[]) {
    this.setAcademyUserPracticesCollection(ownerId, userId, collectionId);

    //TODO -> check not empty fields
    this.buildNewUserPractice(ownerId, userId, collectionId, practiceCards)
    .then(
      practice => this.userPracticesCollection.add(practice)
    ).catch(error => console.log(error));
  }

  private async buildNewUserPractice(ownerId: string, userId: string, collectionId: string, practiceVocablos: vocabloPractice[]):Promise<Practice>{

    let correctAnswers = practiceVocablos.filter(card => card.success === "true").length;
    let totalAnswers = practiceVocablos.length;
    let collection: CardCollection = await this.findDeckInfo(ownerId, collectionId);

    console.log(collection);

    let prueba: Practice = {
      ownerId: ownerId,
      userId: userId,
      collectionId: collectionId,
      name: collection.title,
      description: collection.description,
      creationDate: firebase.firestore.FieldValue.serverTimestamp(),
      correctAnswers: correctAnswers,
      totalAnswers: totalAnswers
    }

    console.log(prueba);

    return prueba;
  }

  private async findDeckInfo(ownerId: string, collectionId: string) {
    return await this.cardService.getCardDeckInfo(ownerId, collectionId);
  }

  public async getCollectionPracticesSnapshot(ownerId: string, userId: string){
    var practicesRef = this.firebase.collection(`academyStudentsPractices/${ownerId}/students/${userId}/practices`).ref;

    try{
      var list: DocumentData [];
      list = await (await practicesRef.get()).docs;
      return list.map(practice => this.mapPracticeVO(practice));
    }catch(error){
      console.log(error);
    }
  }
    
  private mapPracticeVO(practiceVO: DocumentData): Practice {
    console.log(practiceVO.data().creationDate);

    let practice: Practice = {
      userId: practiceVO.data().userId,
      ownerId: practiceVO.data().ownerId,
      collectionId: practiceVO.data().collectionId,
      name: practiceVO.data().name,
      description: practiceVO.data().description,
      creationDate: practiceVO.data().creationDate.toMillis(),
      correctAnswers: practiceVO.data().correctAnswers,
      totalAnswers: practiceVO.data().totalAnswers,
      id: practiceVO.id
    }
    return practice;
  }

}