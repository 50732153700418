<div id="page-header">
    <header [loggedUserId]="this.loggedUserId" [settingsAvailable]=false [logoutAvailable]=false>
    </header>
</div>
<div class="page-body">
    <div class="separator-detail"></div>
    <div class="body-title row">MIS ESTUDIANTES</div>
    <div id="global-items" class="row">
        <div id="students-occupation">
            <div id="students-amount">{{this.studentListSize}}/{{this.studentListLimit}}</div>
            <div id="occupation-label">estudiantes</div>
        </div>
        <div id="academy-code-cntr">
            <div class="label">Código de academia</div>
            <div *ngIf="this.loggedUserId" class="row" id="academy-code-area">
                <div class="col-10">{{this.loggedUserId}}</div>
                <div id="copy-icon" class="col-2"><img src="../../assets/copy-clipboard.png"></div>
            </div>
        </div>
    </div>

    <div *ngIf="this.studentListEmpty" id="noStudentsMessage">
        No hay estudiantes registrados en esta academia
    </div>

    <div *ngIf="this.studentListSize>0" class="row" id="table-field-labels">
        <div class="col-4">email</div>
        <div class="col-2">nombre</div>
        <div class="col-2">desde</div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>

    <div *ngFor="let student of academyStudents" class="row student-row">
        <div id="student-email" class="col-4 field">{{student.email}}</div>
        <div id="student-name" class="col-2 field">{{student.username}}</div>
        <div id="join-date" class="col-2 field">{{student?.subscriptionDate?.toDate() | date:'dd/MM/yyyy'}}</div>
        <div id="student-page-link" class="col-2 field">
            <a href="/students/{{student.id}}/metrics" class="clicable" target="blank">Ver</a>
        </div>
        <div id="student-disjoin-btn" class="col-2 field">
            <a class="clicable" (click)="openDeactivationModal(student.id)"><img src="../../assets/trash_blue.png"></a>
        </div>
    </div>
</div>