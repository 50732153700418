import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { AuthService } from '../services/auth.service';
import { UserInfo } from '../models/user-info';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  userId: string;
  loggedUserEmail: string;
  loggedUser: UserInfo;

  joinedTeacher: UserInfo;

  searchedTeacherCode: string;
  searchedTeacher: UserInfo;

  constructor(
    public userDataService: UserDataService,
    public authService: AuthService,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().then(
      userInfo => {
        this.userId = userInfo.uid;
        this.loggedUserEmail = userInfo.email;
        this.retrieveUserData(this.userId);
      }
    );
  }

  findTeacher() {
    this.userDataService.findTeacherById(this.searchedTeacherCode).then(
      entity => {
        if(entity != null) {
          this.searchedTeacher = entity;
          console.log(entity);
        }
      }
    )
    .catch(error => {
      console.log("Fallo en la busqueda de profe");
      this.searchedTeacher = null;
    });
  }

  retrieveUserData(userId: string){
    this.userDataService.findUserInfoById(this.userId).then(
      user => {
        if(user != null) {
          this.loggedUser = user;
          
          if(user.teacherId != null || user.teacherId != ''){
            this.findJoinedTeacher(user.teacherId);
          }
        }
      }
    );
  }

  deactivateSubscription() {
    this.userDataService.deactivateStudentTeacherSubscription(this.userId, this.joinedTeacher.id, this.onSubscription).then(
      () => {
        //console.log("resuelto en componente");
      }
    );
    
  }

  private findJoinedTeacher(teacherId: string){
    this.userDataService.findTeacherById(teacherId).then(
      entity => {
        if(entity != null) {
          this.joinedTeacher = entity;
          console.log(entity);
        }
      }
    )
    .catch(error => {
      console.log(error);
      console.log("Fallo en la búsqueda del profe asociado");
      this.joinedTeacher = null;
    });
  }

  async updateTeacherCode() {
    this.userDataService.updateUserAcademyInfo(this.userId, this.searchedTeacherCode, this.onSubscription);
  }

  private onSubscription = () => {
    
    window.location.reload();
   
  }

  openDeactivationModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.id = "modal-component";
    
    dialogConfig.data = {
      title: "Eliminar suscripción",
      text: "Dejarás de tener acceso las colecciones y tarjetas de esta academia.",
      error: false,
      onAccept: () => this.deactivateSubscription()
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }
  
}
